import {AfterViewInit, Component, OnInit, ViewContainerRef} from '@angular/core';
import {BaThemeSpinner} from './theme/services/baThemeSpinner/baThemeSpinner.service';
import {BaImageLoaderService} from './theme/services/baImageLoader/baImageLoader.service';
import {GlobalState} from './global.state';
import {BaThemeConfig} from './theme/theme.config';
import {layoutPaths} from './theme/theme.constants';
import {BaThemePreloader} from './theme/services/baThemePreloader/baThemePreloader.service';
import {AuthenticationService} from './_services/authentication/authentication.service';

@Component({
  selector: 'app-root',
  template: `
    <main [ngClass]="{'menu-collapsed': isMenuCollapsed}" baThemeRun>
      <app-loader></app-loader>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
    </main>`,
  styleUrls: ['./theme/initial.scss']
})
export class AppComponent implements AfterViewInit, OnInit {

  isMenuCollapsed = false;

  constructor(private _state: GlobalState,
              private _imageLoader: BaImageLoaderService,
              private _spinner: BaThemeSpinner,
              private viewContainerRef: ViewContainerRef,
              private themeConfig: BaThemeConfig,
              private authSvc: AuthenticationService) {

    themeConfig.config();
    this._loadImages();

    this._state.subscribe('menu.isCollapsed', (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });

  }

  public ngAfterViewInit(): void {
    // hide spinner once all loaders are completed
    BaThemePreloader.load().then((values) => {
      this._spinner.hide();
    });
  }

  private _loadImages(): void {
    // register some loaders
    BaThemePreloader.registerLoader(this._imageLoader.load(layoutPaths.images.root + 'sky-bg.jpg'));
  }

  ngOnInit(): void {
  }
}
