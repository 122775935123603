import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

import {TypeaheadMatch} from 'ngx-bootstrap';
import {Observable} from 'rxjs';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ListPickerComponent),
  multi: true
};


@Component({
  selector: 'pavweb-list-picker',
  templateUrl: './list-picker.component.html',
  styleUrls: ['./list-picker.component.scss'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class ListPickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() data: Observable<any[]>;
  @Input() compFunction: (a, b) => boolean = (a, b) => !a && !b || a == b;
  @Input() searchItemTemplate: TemplateRef<any>;
  @Input() listItemTemplate: TemplateRef<any>;
  @Input() showField: string = 'name';
  @Input() minLength: number = 3;

  @Output() searchValueChanged: EventEmitter<string> = new EventEmitter<string>();
  private _searchValue;

  public get searchValue() {
    return this._searchValue;
  };

  public set searchValue(value) {
    this._searchValue = value;
    this.searchValueChanged.next(value);
  }

  public asyncWorking = false;

  public itemsList = [];
  private _informOnChange: (x) => {};
  private _informTouched: () => {};

  constructor() {

  }


  ngOnInit(): void {


  }

  ngOnDestroy(): void {
  }

  onTypeAheadCourseSelected(match: TypeaheadMatch) {
    if (this.itemsList.findIndex(this.compFunction) == -1) {
      this.itemsList.push(match.item);
      this._informOnChange(this.itemsList);
    }

    this.searchValue = null;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.itemsList = [];
      return;
    }

    this.itemsList = obj;
  }

  registerOnChange(fn: any): void {
    this._informOnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._informTouched = fn;
  }

  removeItem(item: any) {
    this.itemsList = this.itemsList.filter(x => !this.compFunction(x, item));
    this._informOnChange(this.itemsList);
  }
}
