import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';


import {ApiService} from '../../../_services/api.service';
import {Contact} from '../../../../models/Contact';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss']
})
export class ContactEditComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  @ViewChild('contactForm', {static: false}) contactForm;


  _contact: Contact;

  isRequesting = false;

  @Input() set contact(contact: Contact) {
    this._contact = contact;
  };

  get contact(): Contact {
    return this._contact;
  }

  @Input() isClient: boolean = false;
  @Input() isModal: boolean = false;
  @Input() buttonValue: string = 'Save Changes';
  @Input() uniqueEmail: boolean = false;
  @Input() uniqueUserName: boolean = false;
  @Input() isEdit: boolean = false;

  @Output('changesSaved') changesSaved = new EventEmitter<Contact>();

  subscriptions: Subscription[] = [];

  yesNoOptions: { id: boolean, text: string }[] = [
    {
      id: true,
      text: 'Yes'
    },
    {
      id: false,
      text: 'No'
    }
  ];

  countries$: Observable<any>;

  constructor(private apiService: ApiService) {
    this._contact = new Contact();
    this._contact.id = -1;
  }

  ngOnInit() {
    this.countries$ = this.apiService.getCountries();
  }

  onSubmit() {
    this.changesSaved.next(this.contactForm.value);
  }

  countryCompare(a, b) {
    return !a && !b || a === b || (a && b && a.code == b.code);
  }
}
