import {BillingAddress} from './BillingAddress';
import {CourseBookingPaymentMethod} from './CourseRunBookingPaymentMethod';
import {Country} from './Country';
import {Currency} from './Currency';
import {Serializable} from './Serializable';

export class Company implements Serializable<Company> {
  id: number;
  name: string;
  billingAddress: BillingAddress;
  phoneNumber: string;
  email: string;
  preferredBookingPaymentMethod: CourseBookingPaymentMethod;
  currency: Currency;
  taxRate: string;
  preferredCountry: Country;
  timezone: string;
  logo: string;
  logoFileName?: string;
  logoFile?: string;
  sendingEmailAddress: string;
  sendingSMTPAddress: string;
  sendingEmailUser: string;
  sendingEmailPassword: string;
  sendingReplyToName: string;
  sendingEmailPasswordSet?: boolean;

  constructor() {
    this.billingAddress = new BillingAddress();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.name = input.name;
    this.billingAddress = new BillingAddress().deserialize(input.billingAddress);
    this.phoneNumber = input.phoneNumber;
    this.email = input.email;
    this.preferredBookingPaymentMethod = input.preferredBookingPaymentMethod;
    this.currency = new Currency().deserialize(input.currency);
    this.taxRate = input.taxRate;
    this.preferredCountry = new Country().deserialize(input.preferredCountry);
    this.timezone = input.timezone;
    this.logo = input.logo;
    this.logoFile = input.logoFile;
    this.logoFileName = input.logoFileName;

    return this;
  }
}
