import {Contact} from './Contact';
import {BillingAddress} from './BillingAddress';
import {Company} from './Company';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';

export class CustomerCompany implements Serializable<CustomerCompany> {
  id: string | number;
  owner: Contact = null;
  allowedContacts: Contact[] = [];
  billingAddress: BillingAddress = new BillingAddress();
  name: string;
  phoneNumber: string;
  email: string;
  logo: string;
  company: Company;
  isPartner:boolean;
  discountCode:string;
  constructor() {
    this.id = UUID();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.owner = new Contact().deserialize(input.owner);

    if (input.allowedContacts) {
      this.allowedContacts = input.allowedContacts.map(item => {
        const allowedContact = new Contact().deserialize(item);
        return allowedContact;
      });
    }

    this.billingAddress = new BillingAddress().deserialize(input.billingAddress);
    this.name = input.name;
    this.phoneNumber = input.phoneNumber;
    this.email = input.email;
    this.logo = input.logo;
    this.company = new Company().deserialize(input.company);

    return this;
  }
}
