import {CourseRun} from './CourseRun';
import {StaffMember} from './StaffMember';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';
import {Company} from './Company';
import {Resource} from './Resource';

export class CourseSession implements Serializable<CourseSession> {
  color?: string;
  id: number | string;
  courseRun: CourseRun;
  startDate: string;
  endDate: string;
  notes?: string;
  requiredResources?;
  assignedResources?: Resource[];
  calendarColor?: string;
  assignedStaffMembers?: StaffMember[];
  price?;
  timeZone?;
  billingCompany?: Company;

  constructor() {
    this.id = UUID();
    this.courseRun = new CourseRun();
    this.assignedStaffMembers = [];
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.courseRun = new CourseRun().deserialize(input.courseRun);
    this.startDate = input.startDate;
    this.endDate = input.endDate;
    this.notes = input.notes;
    this.requiredResources = input.requiredResources;
    this.assignedResources = input.assignedResources;
    this.calendarColor = input.calendarColor;
    this.timeZone = input.timeZone;
    return this;
  }
}


