import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {Component} from '@angular/core';
import * as moment from 'moment';
import {Company} from '../../../../../models/Company';
import BigNumber from 'bignumber.js';
import {PriceElement} from '../../../../../models/PriceElement';

@Component({
  templateUrl: './price-element-modal.html'
})
export class PriceElementModal {
  private _priceElement: PriceElement;
  taxSelector: string = 'no-tax';
  providedTotal: number = 0;

  set priceElement(priceElement: PriceElement) {

    this._priceElement = (new PriceElement).deserialize(priceElement);
    if (priceElement.applyTax) {
      this.taxSelector = 'plus-tax';
    }
    this.providedTotal = this.getAmountTotal().toNumber();
  }

  get priceElement() {
    return this._priceElement;
  }

  billingCompany: Company;

  constructor(public bsModalRef: BsModalRef, private modalSvc: BsModalService) {
    this._priceElement = new PriceElement();
  }

  getAmountTotal() {
    const amount = new BigNumber(this._priceElement.amount || 0);
    const taxPercentage = new BigNumber(this._priceElement.applyTax ? this.billingCompany.taxRate : 0);
    const providedTotal = new BigNumber(this.providedTotal ? this.providedTotal : 0);

    const taxAmount = amount.multipliedBy(taxPercentage).div(100);
    if (this.taxSelector == 'inc-tax') {
      return providedTotal;
    }

    return amount.plus(taxAmount);
  }


  changedSelector($event) {
    return this._priceElement.applyTax = ($event === 'plus-tax' || $event === 'inc-tax')
  }

  submitForm(form) {
    if (form.invalid) {
      Object.keys(form.controls).forEach(field => {
        const control = form.controls[field];
        control.markAsTouched({onlySelf: true});
      });
      return;
    }

    // form is valid at this point
    let amount = form.value.amount;

    if (this.taxSelector === 'inc-tax') {

      const providedTotal = new BigNumber(this.providedTotal);
      const taxRate = new BigNumber(this.billingCompany.taxRate).div('100');
      const test = new BigNumber(1).plus(taxRate);
      amount = providedTotal.div(test).toFixed(10);
    }

    this.priceElement = new PriceElement().deserialize({
      id: 1 - parseInt(moment().format('x')),
      company: this.billingCompany,
      description: form.value.description,
      amount,
      applyTax: this._priceElement.applyTax,
      course: null
    });

    this.modalSvc.setDismissReason('savedChanges');
    this.bsModalRef.hide();
  }
}
