export enum CourseBookingPaymentMethod {
  SAGEPAY = 'sagepay',
  INVOICE = 'invoice',
  CARD = 'card',
  AMEX = 'amex',
  CASH = 'cash',
  CHEQUE = 'cheque',
  BACS = 'bacs',
  STANDING_ORDER = 'standingOrder',
  STRIPE = 'stripe',
  REFUND = 'refund',
  GIFT_VOUCHER = 'giftVoucher',
}
