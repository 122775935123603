import {CourseRunBooking} from './CourseRunBooking';
import {Company} from './Company';
import {Contact} from './Contact';
import {Invoice} from './Invoice';
import {CustomerCompany} from './CustomerCompany';
import {Offer} from './Offer';
import {GiftVoucher} from './GiftVoucher';
import {Serializable} from './Serializable';

export class BookingOrder implements Serializable<BookingOrder> {
  id: number;
  bookings: CourseRunBooking[];
  company: Company;
  customer: Contact;
  status: string;
  createdAt: Date;
  cancelledAt?: Date;
  invoice: Invoice;
  customerCompany: CustomerCompany;
  offers: Offer[];
  giftVouchers: GiftVoucher[];
  totalAmount = '0';

  constructor() {
    this.bookings = [];
    this.company = new Company();
    this.customer = new Contact();
    this.invoice = new Invoice();
    this.offers = [];
    this.giftVouchers = [];

  }

  getOfferMaxCount(affectingOffer: Offer): number {
    if (this.offers.findIndex((offer) => {
      return offer.id === affectingOffer.id;
    }) === -1) {
      return 0;
    }

    return this.bookings.reduce((maxCount: number, booking) => {
      if (maxCount == null) {
        return booking.getParticipantsCount();
      }
      if (booking.getParticipantsCount() < maxCount) {
        return booking.getParticipantsCount();
      }
      return maxCount;

    }, null);
  }

  hasMembership() {
    return this.bookings.some(booking => booking.hasMembership())
  }

  deserialize(input): BookingOrder {
    if (!input) {
      return null;
    }
    if (input.bookings) {
      this.bookings = input.bookings.map(item => {
        const booking = new CourseRunBooking().deserialize(item);
        return booking;
      });
    }

    this.company = new Company().deserialize(input.company);
    this.customer = new Contact().deserialize(input.customer);
    this.invoice = new Invoice().deserialize(input.invoice);
    if (input.customerCompany) {
      this.customerCompany = new CustomerCompany().deserialize(input.customerCompany);
    }
    if (input.offers) {
      this.offers = input.offers.map(item => {
        const offer = new Offer().deserialize(item);
        offer.company = this.company;
        return offer;
      });
    }

    if (input.giftVouchers) {
      this.giftVouchers = input.giftVouchers.map(item => {
        const giftVoucher = new GiftVoucher().deserialize(item);
        giftVoucher.bookingOrder = this;
        giftVoucher.company = this.company;
        return giftVoucher;
      });
    }
    return this;
  }
}
