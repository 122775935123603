import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {BsModalRef, BsModalService} from 'ngx-bootstrap';
import {ApiService} from '../../../../_services/api.service';
import {NgForm} from '@angular/forms';


import {Payment} from '../../../../../models/Payment';
import {CourseBookingPaymentMethod} from '../../../../../models/CourseRunBookingPaymentMethod';
import * as moment from 'moment';
import {ToastyService} from 'ng2-toasty';
import {ToastrService} from 'ngx-toastr';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'pavweb-add-payment-modal',
  templateUrl: './add-payment-modal.component.html',
  styleUrls: ['./add-payment-modal.component.scss']
})
export class AddPaymentModalComponent implements OnInit {
  disablePaymentStatus: boolean;
  paymentMethods$: Observable<string[]>;
  @Input() order;
  @Input() invoice;
  @Input("paymentMethod")
  set paymentMethod(val)
  {
    this.paymentMethodSelected = val;  
  }
  get paymentMethod(){
    return this.paymentMethodSelected ;
  }
  

  public payment: Payment;
  error: any;
  paymentStatus: string;
  paymentMethodSelected: CourseBookingPaymentMethod;

  CourseBookingPaymentMethod = CourseBookingPaymentMethod;
  public dueDate: moment.Moment;

  constructor(
    public bsModalRef: BsModalRef,
    public apiSvc: ApiService,
    private modalSvc: BsModalService,
    private toastySvc: ToastrService) {

  }

  ngOnInit() {
    this.paymentMethods$ = this.apiSvc.getPaymentMethods();
    //.pipe(tap(val=>console.log("PaymentMethods",val)));
  }

  savePayment(paymentForm: NgForm) {
    if (paymentForm.invalid) {
      Object.keys(paymentForm.controls).forEach(field => {
        const control = paymentForm.controls[field];
        control.markAsTouched({onlySelf: true});
      });
      return;
    }
    if (paymentForm.value.datePaid) {
      paymentForm.value.paidAt = (<moment.Moment>paymentForm.value.datePaid).hour(12).format();
    }

    const payment = <Payment>paymentForm.value;
    payment.paymentMethod=this.paymentMethodSelected;
    let observable;
    if (this.invoice) {
      observable = this.apiSvc.createInvoicePayment(this.invoice, payment);
    }

    if (this.order) {
      observable = this.apiSvc.createPayment(this.order, payment);
    }

    if (!observable) {
      this.modalSvc.setDismissReason('error');
      this.bsModalRef.hide();
      return;
    }
    //here we have a valid state for the payment
    observable.subscribe((payment) => {
      //debugger;
      this.payment = payment;
      this.modalSvc.setDismissReason('paymentAdded');
      this.bsModalRef.hide();
    }, (httpErrorResponse) => {
      //debugger;
      let info = 'Failed to add Payment';
      if (httpErrorResponse.error && httpErrorResponse.error.errors && httpErrorResponse.error.errors[0]) {
        info += ' - ' + httpErrorResponse.error.errors[0].description
      }
      this.toastySvc.error(info);

      this.modalSvc.setDismissReason('error');
      this.bsModalRef.hide();
    })
  }

  changedPaymentMethod(paymentMethod: CourseBookingPaymentMethod) {
    
    this.paymentMethodSelected = paymentMethod;
    if (paymentMethod === CourseBookingPaymentMethod.INVOICE) {
      console.log("paymentMethod===>",paymentMethod)
      if (this.order) {
        this.dueDate = this.order.bookings.reduce((acc, booking) => {
          const possibleDueDate = moment(booking.courseRun.startDate).subtract(30, 'days');
          if (possibleDueDate.isBefore(acc) && !possibleDueDate.isBefore(moment())) {
            acc = possibleDueDate;
          }

          return acc;
        }, moment());

      }

      this.paymentStatus = 'pending';
    } else {
      this.paymentStatus = 'paid';
    }

    if (paymentMethod === CourseBookingPaymentMethod.GIFT_VOUCHER) {
      this.paymentStatus = 'paid';
      this.disablePaymentStatus = true;
    } else {
      this.disablePaymentStatus = false;
    }
  }
}

