import {Serializable} from './Serializable';

export class Country implements Serializable<Country> {
  code: string;
  name: string;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.code = input.code;
    this.name = input.name;
    return this;
  }
}
