import {Company} from './Company';
import {Serializable} from './Serializable';


export class ResourceType implements Serializable<ResourceType> {
  id: string | number;
  name: string;
  company: Company;
  enabled = true;

  constructor() {
    this.company = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.name = input.name;
    this.company = new Company().deserialize(input.company);
    this.enabled = input.enabled;
    return this;
  }
}


