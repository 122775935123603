import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ApiService } from 'app/_services/api.service';
import { SystemCompanyService } from 'app/_services/system-company.service';
import * as moment from 'moment';
import { switchMap, map, take, shareReplay } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { CourseSession } from 'models/CourseSession';
import * as _ from 'lodash';
import { Company } from 'models/Company';
import { FormControl } from '@angular/forms';
import { compareFunc } from 'app/utils/Utils';
import { Moment } from 'moment';
import { PhysicalLocation } from 'models/Location';
import { StaffStatusColor } from 'app/_constants/staff-status';
import { AuthenticationService } from 'app/_services';
import { Contact } from 'models/Contact';

@Component({
  selector: 'my-calendar',
  templateUrl: './my-calendar.component.html',
  styleUrls: ['./my-calendar.component.scss']
})
export class MyCalendarComponent implements OnInit {
  @ViewChild('calendar', {static: true}) calendar;
  courseEvents: CourseSession[];
  currentCompany: any;
  events: any;
  // locations$: Observable<any>;
  currentCompany$: Observable<Company>;
  // locationControl:FormControl;
  compareFn = compareFunc;
  calendarOptions:any;
  currentDate: any;
  startDate: any;
  currentLocation: any;
  endDate: any;
  statusColor:StaffStatusColor;
  currentUser:Contact;

  @Input('location')
  set location(val) {
    if (val != null&&val!=undefined) {
        this.currentLocation=val;
        console.log("location",this.currentLocation);
        this.getEvents(this.currentLocation);
    }
  }

  @Input('company')
  set company(val) {
    if (val != null&&val!=undefined) {
       this.currentCompany=val;
    }
  }

  constructor(private userCalendarService: ApiService,
    private globalService: ApiService,
    private companySvc: SystemCompanyService,
    private authSvc: AuthenticationService,
    ) {
      // this.locationControl = new FormControl();
      this.statusColor=new StaffStatusColor();
    }

  ngOnInit() {
    this.authSvc.getProfileData().subscribe(data=>{
      this.currentUser=data;
      //console.log(data);
    })
    //this.getEvents();
    this.setSyncVariables();
    this.setCalendar();
  }

  setSyncVariables()
  {
    this.currentCompany$ = this.companySvc.getCompany().pipe(shareReplay(1));
    // this.locations$ = this.currentCompany$.pipe(
    //   switchMap((company)=>{
    //     //console.log("locations>>>>",company)
    //     this.currentCompany=company;
    //     return this.globalService.getLocations({ jsonQuery: { 'company.id': company.id }, 'no-page': true }).pipe(map(response=>{
    //       this.locationControl.patchValue(response.data[0]);
    //       //console.log("locations>>>>",response.data)
    //       return response.data;}))
    //   })
    // )
    // this.locationControl.valueChanges.subscribe(val=>{
    //   this.getEvents(val);
    //   this.currentLocation=val;
    // })
  }
  setCalendar() {
    this.calendarOptions = {
      customButtons: {
        customNext: {
          icon: 'right-single-arrow',
          click: () => {
            this.calendar.fullCalendar('next');
            this.currentDate = this.calendar.fullCalendar('getDate').toDate();
             this.getEvents(this.currentLocation);
          }
        },
        customPrev: {
          icon: 'left-single-arrow',
          click: () => {
            this.calendar.fullCalendar('prev');
            this.currentDate = this.calendar.fullCalendar('getDate').toDate();
            this.getEvents(this.currentLocation);

          }
        },
        customToday: {
          text: 'today',
          click: () => {
            this.calendar.fullCalendar('today');
            this.currentDate = this.calendar.fullCalendar('getDate').toDate();
            this.getEvents(this.currentLocation);
          }
        }
      },

      height: '200px',
      fixedWeekCount: false,
      defaultView: 'agendaWeek',
      scrollTime: '08:00:00',
      schedulerLicenseKey: '0158708692-fcs-1502048650',
      defaultDate: this.startDate,
      groupByResource: true,
      firstDay: 1,

      header: {
        left: 'customPrev,customNext,customToday',
        center: 'title',
        right: '',

      },

      minTime: '08:00',
      maxTime: '21:00',
      titleFormat: 'MMMM Do, YYYY',
      editable: false,
      droppable: false,
      eventResourceEditable: true,
      eventLimit: true, // allow "more" link when too many events
      resourceOrder: 'index',
      refetchResourcesOnNavigate: true,
      events: (startDate: Moment, endDate: Moment, tz, callback) => {
        if (this.events != undefined)
          callback(this.events);
      },
      eventRender: (event, element) => {
        element.qtip({
          content: event.title,
          position: {
            target: 'mouse',
            adjust: { x: 5, y: 5 }
          }
        })
        if (event.eventType == 2 && event.status!=1) {
          $(element).find(".fc-content")[0].setAttribute("style", "color: black;");
        }
        else  if (event.eventType == 2 && event.status==1) {
          ////console.log(event,element);
          $(element)[0].setAttribute("style", "opacity: 0.5");
          $(element)[0].setAttribute("event_id",event.id);
          $(element)[0].setAttribute("eventType",event.eventType);

        }
      },
      resourceRender: (resourceObj, labelTds, bodyTds) => {
        labelTds.css('background', 'gray');
      },
    };

  }

  getEvents(location:PhysicalLocation) {
   console.log(location,this.currentCompany);
   this.calendar.fullCalendar('refetchEvents');
   ////console.log(this.calendar.fullCalendar('getDate'));
   this.startDate=this.calendar.fullCalendar('getDate');
   this.endDate=this.calendar.fullCalendar('getDate').add(6,'d');
   ////console.log( this.startDate, this.endDate,this.currentUser.staffMember.id);
   let params: { jsonQuery?: any, 'no-page'?: boolean } = {
    jsonQuery: {
      $and: [
        { endDate: { $lte: this.endDate.endOf('day').format("YYYY-MM-DD HH:mm") } },
        { startDate: { $gte: this.startDate.startOf('day').format("YYYY-MM-DD HH:mm") } },
        {'staffMember.id':{$eq:this.currentUser.staffMember.id}},
        {'staffMember.workLocations.id': {$in:[location.id]}},
        {'staffMember.primaryCompany.id': {$eq:this.currentCompany.id}},
      ],

    },
    'no-page': true,
  };

    forkJoin(this.userCalendarService.getStaffEvents(this.startDate, this.endDate, this.currentCompany, location,this.currentUser.staffMember.id).pipe(
      map((events: CourseSession[]) => {
        ////console.log("original events",events)
        events = events.filter(e => { return e.assignedStaffMembers.length > 0 });
       // this.events = [];
        this.courseEvents = events;
        ////console.log(this.resources);
        return events.map((event) => {
          const eventStartDate = moment(event.startDate).tz(this.currentCompany.timezone);
          const eventEndDate = moment(event.endDate).tz(this.currentCompany.timezone);

          return {
            title: `${event.courseRun.course.name}  (${event.courseRun.maxNumbers - event.courseRun.participantsCount}/${event.courseRun.maxNumbers} )`,
            start: eventStartDate,
            end: eventEndDate,
            color: event.courseRun.course.calendarColor,
            id: event.id,
            resourceIds: event.assignedStaffMembers.map(assignedStaffMember => assignedStaffMember.id),
            eventData: event,
            status: 1,
            unavailableIds: [],
            eventType: 1,
            editable: false
          }
        });
      })

    ), this.globalService.getStaffsAvailability(params).pipe())
    .subscribe(([courseRuns,staffAvailability])=>{
      this.events=courseRuns;
      this.prepareStaffAvailability(staffAvailability);
      ////console.log('response',courseRuns,staffAvailability);
      ////console.log('Events', this.events);
      this.calendar.fullCalendar('refetchEvents');

    });

  }
  prepareStaffAvailability(data) {
    this.events = this.events.filter(event => event.eventType == 1);
    ////console.log(data);
    data.forEach(element => {
      //if(element.status=="1"||element.status=="4")
      {
        let title = "Working";
        let color = "#608572";
        color= this.statusColor.getColor(element.status);
        title=this.statusColor.getTitle(element.status);
        let event = {
          title: title,
          start: moment(element.startDate).tz(this.currentCompany.timezone),
          end: moment(element.endDate).tz(this.currentCompany.timezone),
          color: color,
          id: element.id,
          resourceId: element.staffMember.id,
          status: element.status,
          lunchStartTime: element.lunchStartTime,
          lunchEndTime: element.lunchEndTime,
          startDate: element.startDate,
          endDate: element.endDate,
          eventType: 2,
          className: 'black-text',
          description:element.description,
        }
        if(element.status==8)
        {
          event.title +=": "+element.description;
          //console.log('other');
        }
        if(element.status==1)
        {
          let lunchEvent = {
            title: 'Lunch break',
            start: moment(element.lunchStartTime).tz(this.currentCompany.timezone),
            end: moment( element.lunchEndTime).tz(this.currentCompany.timezone),
            color:  this.statusColor.getColor(10),
            id: element.id*10,
            resourceId: element.staffMember.id,
            eventType: 3,
           // rendering:'background'
          }
          this.events.push(lunchEvent);
          //event.color=this.statusColor.Unavailable;
          event["rendering"]='background';
        }
        this.events.push(event);
      }
    })
    ////console.log('after', this.events);
  }
}
