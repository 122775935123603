import {ApiService} from '../../../_services/api.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {Country} from '../../../../models/Country';
import {Currency} from '../../../../models/Currency';
import {Invoice} from '../../../../models/Invoice';
import {BsModalService} from 'ngx-bootstrap';
import {InvoiceItemEditModalComponent} from './invoice-item-edit-modal/invoice-item-edit-modal.component';
import {InvoiceItem} from '../../../../models/InvoiceItem';
import {UUID} from '../../../utils/Utils';
import {BigNumber} from 'bignumber.js';
import {AddPaymentModalComponent} from '../../bookings/booking-edit/addPaymentModal/add-payment-modal.component';
import * as FileSaver from 'file-saver';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ComponentCanDeactivate} from '../../../_guards/pending-changes/pending-changes.guard';
import {ToastrService} from 'ngx-toastr';
import {catchError, finalize, share, switchMap, take, tap} from 'rxjs/operators';
import * as moment from 'moment';
import {SystemCompanyService} from 'app/_services/system-company.service';
import {Company} from 'models/Company';

@Component({
  selector: 'invoice-edit',
  templateUrl: './invoice-edit-page.component.html',
  styleUrls: ['./invoice-edit-page.component.scss']
})
export class InvoiceEditPageComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild('form', {static: true}) form: NgForm;
  @ViewChild('testInvoicePrint', {static: true}) testInvoicePrint: ElementRef;
  isRequesting = false;
  isEdit = false;
  invoice$: Observable<Invoice>;
  countries$: Observable<Country[]>;
  currencies$: Observable<Currency[]>;
  private invoiceId: any;
  private invoice: Invoice;
  private invoceIdSubject = new BehaviorSubject<number>(0);

  invoiceData: FormGroup;
  invoiceNotFound: boolean;

  @Input('inoviceId')
  set inoviceId(val) {
    this.invoceIdSubject.next(val);
    console.log('Invoice Id =>', val);
  }

  @Input() paymentMethod;
  @Input() order;
  @Input() itemsDisplayHorizontal = true;
  @Input() paymentStatus;
  @Output() paymentAddEvent: EventEmitter<any> = new EventEmitter();
  currentDate: Date;
  company$: Observable<Company>;

  constructor(private apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router,
              private cdr: ChangeDetectorRef,
              private toastySvc: ToastrService,
              private modalSvc: BsModalService,
              private companySvc: SystemCompanyService,
              private fb: FormBuilder
  ) {
    this.currentDate = new Date;
    this.invoiceData = this.fb.group({
      id: [null],
      date: [{value: null, disabled: false}, Validators.compose([Validators.required])],
      dueDate: [null]
    })
  }

  canDeactivate(): (boolean | Observable<boolean>) {
    return !this.form || !this.form.dirty
  }

  ngOnInit() {
    this.company$ = this.companySvc.getCompany().pipe(share());
    this.countries$ = this.apiService.getCountries().pipe(
      share()
    );
    this.currencies$ = this.apiService.getCurrencies().pipe(
      share()
    );
    this.route.params.subscribe((params: Params) => {

      if (params.id != undefined) {
        console.log('paramters', params)
        this.invoceIdSubject.next(params.id);
      }
    });

    this.loadInvoice();

    // this.invoice$ = this.route.params.pipe(
    //   switchMap((params: Params) => {
    //     if (!params.id) {
    //       return of(new Invoice());
    //     }
    //     this.isEdit = true;
    //     this.invoiceId = params.id;
    //     return this.apiService.getInvoice(params.id)
    //   }),
    //   tap((invoice) => {
    //     this.invoice = invoice;
    //   })
    // );
  }

  loadInvoice() {
    this.invoice$ = this.invoceIdSubject.pipe(
      switchMap(val => {
        if (!val) {
          return of(new Invoice());
        }
        console.log('Edit Invoice=>', val);
        this.isEdit = true;
        this.invoiceId = val;
        return this.apiService.getInvoice(val).pipe(
          catchError(err => {
            this.notFound();
            return throwError(err);
          })
        )
      }),
      tap((invoice) => {
        this.invoice = invoice;
        this.invoiceData.patchValue(invoice);
        console.log('Invoice =====>', invoice);
        // get invoice due
        // this.invoice.payments = this.invoice.payments.map(p => {
        //   if (p.status == 'pending' && moment(this.currentDate).diff(moment(p.dueDate), 'days') > 0)
        //      (p as any).className = 'overdue';
        //   else if (p.status == 'pending')
        //      (p as any).className = 'due';
        //   else if (p.status == 'paid')
        //     (p as any).className = 'paid';
        //     console.log(p);
        //   return p;
        // })

      })
    );
  }

  onSubmit() {
    let invoice = (this.invoice as any);
    invoice.invoiceItems = this.invoice.invoiceItems;
    invoice.dueDate = moment(this.invoiceData.value.dueDate).format('DD-MM-YYYY');
    invoice.date = moment(this.invoiceData.value.date).format('DD-MM-YYYY');

    this.apiService.updateInvoice(invoice).pipe(
      finalize(() => {
        this.isRequesting = false;
      })
    )
      .subscribe(
        () => {
          //the update was successful
          this.toastySvc.info(
            'Invoice updated ',
            'Updated',
          );
        },
        (error) => {

          const errorData = {
            title: 'Failed',
            msg: 'Operation failed'
          };

          if (error.error && error.error.description) {

            errorData.msg = `Failed to save changes: ${error.error.description}`;
          }
          this.toastySvc.error(
            errorData.msg,
            errorData.title
          );
        }
      );

  }

  addItem() {
    //const modal = this.modalSvc.show(InvoiceItemEditModalComponent, {"class": 'modal-lg'});
    const modal = this.modalSvc.show(InvoiceItemEditModalComponent);
    const component = <InvoiceItemEditModalComponent>modal.content;
    component.item = new InvoiceItem();
    component.company = this.invoice.company;

    this.modalSvc.onHidden.pipe(
      take(1),
      tap((reason) => {
        if (reason === 'saveChanges') {
          this.invoice.invoiceItems.push(component.item);
        }
      })
    )
      .subscribe()

  }

  editItem(invoiceItem: InvoiceItem) {
    const modal = this.modalSvc.show(InvoiceItemEditModalComponent);
    const component = <InvoiceItemEditModalComponent>modal.content;
    const toEditInvoiceItem = new InvoiceItem().deserialize(invoiceItem);
    toEditInvoiceItem.id = UUID();
    component.item = toEditInvoiceItem;
    component.company = this.invoice.company;

    this.modalSvc.onHidden.pipe(
      take(1),
      tap((reason) => {
        if (reason === 'saveChanges') {

          this.invoice.invoiceItems = this.invoice.invoiceItems.reduce((array, currentInvoiceItem) => {
            let toSaveItem = currentInvoiceItem;
            if (currentInvoiceItem.id === invoiceItem.id) {
              toSaveItem = component.item;
            }
            return [...array, toSaveItem];
          }, [])
        }
      })
    )
      .subscribe();
  }

  deleteItem(invoiceItem: InvoiceItem) {
    if (confirm('Are you sure you want to delete this item?')) {
      this.invoice.invoiceItems = this.invoice.invoiceItems.filter(oldItem => oldItem.id !== invoiceItem.id)
    }
  }

  getItemTaxAmount(invoiceItem: InvoiceItem) {
    return new BigNumber(
      new BigNumber(invoiceItem.amount).multipliedBy(invoiceItem.taxPercentage).div(100).toFixed(2)
    )
  }

  getItemTotal(invoiceItem: InvoiceItem) {
    return new BigNumber(
      new BigNumber(invoiceItem.amount).plus(this.getItemTaxAmount(invoiceItem)).toFixed(2)
    )
      .multipliedBy(invoiceItem.quantity);
  }

  getNetTotal() {
    return this.invoice.invoiceItems.reduce((acc, item) => {
      return acc.plus(new BigNumber(item.amount).multipliedBy(item.quantity));
    }, new BigNumber(0))
  }

  getTaxTotal() {
    return this.invoice.invoiceItems.reduce((acc, item) => {
      return acc.plus(this.getItemTaxAmount(item).multipliedBy(item.quantity));
    }, new BigNumber(0))
  }

  getGrandTotal() {
    return this.getNetTotal().plus(this.getTaxTotal());
  }

  addPayment() {

    const modal = this.modalSvc.show(AddPaymentModalComponent);
    const component = <AddPaymentModalComponent>modal.content;
    if (this.order && this.order.createdAt != undefined && this.order.createdAt != '') {
      console.log(this.order);
      component.dueDate = moment(this.order.createdAt).add('30', 'days');
    }
    if (this.paymentStatus)
      component.paymentStatus = this.paymentStatus;
    if (this.order) {
      //from order
      component.order = this.order;
    } else
      component.invoice = this.invoice;
    // set paymentTypeId
    if (this.paymentMethod) {
      component.paymentMethod = this.paymentMethod;
      //console.log("paymentMethod =>",this.paymentMethodId);
    }
    this.modalSvc.onHidden.pipe(
      take(1)
    )
      .subscribe((reason) => {
          console.log(reason)
          if (reason === 'paymentAdded' && !this.order) {
            location.reload();
          } else if (reason === 'paymentAdded') {
            this.invoceIdSubject.next(this.invoiceId);
            if (this.paymentAddEvent)
              this.paymentAddEvent.emit(true);
          }

        }
      )
  }

  downloadPDF() {
    this.apiService.getInvoicePDF(this.invoice).subscribe((result) => {
      FileSaver.saveAs(result, 'test.pdf');
    })


  }

  regenerate() {
    this.apiService.regenerateInvoice(this.invoice).subscribe((invoice) => {
      this.toastySvc.success('Regenerated');
      this.invoice = invoice;
    })
  }

  refreshInvoice() {
    this.invoceIdSubject.next(this.invoiceId);
  }

  deleteInvoice() {
    if (confirm('Are you sure to delete this invoice?')) {
      this.invoice.deleted = true;
      this.apiService.updateInvoice(this.invoice).pipe(
        finalize(() => {
          this.isRequesting = false;
        })
      )
        .subscribe(
          () => {
            //the update was successful
            this.toastySvc.info(
              'Invoice deleted ',
              'Deleted',
            );
            this.router.navigate(['pages/admin/invoices'])
          },
          (error) => {
            const errorData = {
              title: 'Failed',
              msg: 'Operation failed'
            };

            if (error.error && error.error.description) {

              errorData.msg = `Failed to save changes: ${error.error.description}`;
            }
            this.toastySvc.error(
              errorData.msg,
              errorData.title
            );
          }
        );
    }
  }

  notFound() {
    this.invoiceNotFound = true;
    // setTimeout(()=>{
    //   this.router.navigate(['pages/admin/invoices'])
    // },3000);
  }
}
