import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';


import {BaThemeConfig} from './theme.config';

import {BaThemeConfigProvider} from './theme.configProvider';

import {
  BaBackTopComponent,
  BaCardComponent,
  BaChartistChartComponent,
  BaCheckboxComponent,
  BaContentTop,
  BaMenuComponent,
  BaMenuItemComponent,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTopComponent,
  BaPictureUploader,
  BaSidebar,
  ColorPickerComponent,
  PavwebDateTimePickerComponent,
} from './components';

import {BaCardBlurDirective} from './components/baCard/baCardBlur.directive';

import {BaScrollPositionDirective, BaSlimScrollDirective, BaThemeRunDirective} from './directives';

import {BaAppPicturePipe, BaKameleonPicturePipe, BaProfilePicturePipe} from './pipes';

import {BaImageLoaderService, BaMenuService, BaThemePreloader, BaThemeSpinner} from './services';

import {EmailValidator, EqualPasswordsValidator} from './validators';
import {LoaderComponent} from './services/baThemeSpinner/component/loader/loader.component';
import {
  BsDatepickerModule,
  BsDropdownModule,
  ModalModule,
  PaginationModule,
  TabsModule,
  TimepickerModule,
  TypeaheadModule
} from 'ngx-bootstrap';
import {Ng2TableModule} from './ng-table/components/ng-table-module';
import {
  DiscountCodeDirective,
  EqualValidatorDirective,
  UniqueEmailValidatorDirective,
  UniqueUsernameValidatorDirective
} from '../_directives';
import {SpinnerComponent} from '../_services/spinner/spinner/spinner.component';
import {ApiService} from '../_services/api.service';
import {ConfirmModalComponent} from './components/confirmModal/confirm-modal.component';
import {NguiDatetimePickerModule} from '@ngui/datetime-picker';
import {CourseWeekSessionPicker} from './components/course-week-session-picker/course-week-session-picker';
import {CourseWeekSlotsPicker} from './components/course-week-slots-picker/course-week-slots-picker';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatAutocompleteModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatCheckboxModule
} from '@angular/material';
import {MOMENT_DATE_FORMATS, MomentDateAdapter} from '../utils/DateAdapter/MomentDateAdapter';
import {ResourceCalendar} from './components/resourceCalendar/ResourceCalendar';
import {FileUploaderComponent} from './components/fileUploader';
import {SystemCompanyService} from '../_services/system-company.service';
import {ContactEditComponent} from '../pages/contacts/contactEdit/contact-edit.component';
import {AddPaymentModalComponent} from '../pages/bookings/booking-edit/addPaymentModal/add-payment-modal.component';
import {CustomerEditComponent} from '../pages/customers/contactEdit/customer-edit.component';
import {QuillModule} from 'ngx-quill';
import {JsonToCsvService} from '../_services/json-to-csv.service';
import {CourseRunCalendarComponent} from './components/course-run-calendar';
import {ListPickerComponent} from './components/list-picker';
import {CourseEventModalComponent} from '../pages/courseCalendar/courseEventModal';
import {FlexLayoutModule} from '@angular/flex-layout';
import {DragulaModule} from 'ng2-dragula';
import {ParticipantStatusModalComponent} from '../pages/courseCalendar/participant-status-modal/participant-status-modal.component';
import {MaxValidator} from '../_directives/max-validator.directive';
import {MinValidator} from '../_directives/min-validator.directive';
import {MomentModule} from 'ngx-moment';

import {NgSelectModule} from '@ng-select/ng-select';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {SnakeToRegularPipe} from '../_pipes/snake-to-regular.pipe';
import {ImageCropperModule} from 'ngx-image-cropper';
import {AvailabilityDetailsComponent} from 'app/pages/staff-members/availability-details/availability-details.component';
import {CustomerCompaniesSelectorComponent} from 'app/pages/customer-companies/customer-companies-selector/customer-companies-selector.component';
import {CustomerCompanyEditComponent} from 'app/pages/customer-companies/customer-company-edit/customer-company-edit.component';
import {CustomerSelectorComponent} from 'app/pages/customers/customer-selector/customer-selector.component';
import {AddClientModalComponent} from 'app/pages/clientBook/addClientModal/add-client-modal.component';
import {CourseSelectorComponent} from 'app/pages/course/course-selector/course-selector.component';
import {TicketListComponent} from 'app/pages/clientBook/ticket-list.component';
import {TicketItemComponent} from 'app/pages/clientBook/ticket-item.component';
import {InvoiceEditPageComponent} from 'app/pages/invoices/invoice-edit-page/invoice-edit-page.component';
import {InvoiceItemEditModalComponent} from 'app/pages/invoices/invoice-edit-page/invoice-item-edit-modal/invoice-item-edit-modal.component';
import {AddGiftVoucherModalComponent} from 'app/pages/clientBook/add-gift-voucher-modal/add-gift-voucher-modal.component';
import {GiftVoucherComponent} from 'app/pages/clientBook/gift-voucher/gift-voucher.component';
import {ImagePickerComponent} from 'app/pages/clientBook/image-picker/image-picker.component';
import {MomentTimezonePipe} from '../_pipes/moment-timezone';
import {MyCalendarComponent} from './components/my-calendar/my-calendar.component';
import {PriceElementItemComponent} from '../pages/course/course-edit/price-element-item/price-element-item.component';
import {PriceElementModal} from '../pages/course/course-edit/priceElementModal/price-element-modal';
import {NgxUploaderModule} from 'ngx-uploader';
import { AddClientContactModal } from 'app/pages/clientBook/addClientContactModal/add-client-contact-modal.component';
import { ApiWaiverService } from 'app/_services/api-waiver.service';

const NGA_COMPONENTS = [
  BaBackTopComponent,
  BaCardComponent,
  BaChartistChartComponent,
  BaCheckboxComponent,
  BaContentTop,
  BaMenuItemComponent,
  BaMenuComponent,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTopComponent,
  BaPictureUploader,
  BaSidebar,
  LoaderComponent,
  SpinnerComponent,
  ColorPickerComponent,
  PavwebDateTimePickerComponent,
  ConfirmModalComponent,
  CourseWeekSessionPicker,
  CourseWeekSlotsPicker,
  ResourceCalendar,
  FileUploaderComponent,
  ContactEditComponent,
  CustomerEditComponent,
  AddPaymentModalComponent,
  CourseRunCalendarComponent,
  ListPickerComponent,
  CourseEventModalComponent,
  ParticipantStatusModalComponent,
  AvailabilityDetailsComponent,
  CustomerCompaniesSelectorComponent,
  CustomerCompanyEditComponent,
  CustomerSelectorComponent,
  AddClientModalComponent,
  CourseSelectorComponent,
  TicketListComponent,
  TicketItemComponent,
  InvoiceEditPageComponent,
  InvoiceItemEditModalComponent,
  AddGiftVoucherModalComponent,
  AddClientContactModal,
  GiftVoucherComponent,
  ImagePickerComponent,
  MyCalendarComponent,
  PriceElementItemComponent,
  PriceElementModal,
];

const NGA_DIRECTIVES = [
  BaScrollPositionDirective,
  BaSlimScrollDirective,
  BaThemeRunDirective,
  BaCardBlurDirective,
  EqualValidatorDirective,
  UniqueEmailValidatorDirective,
  UniqueUsernameValidatorDirective,
  DiscountCodeDirective,
  MaxValidator,
  MinValidator,
];

const NGA_PIPES = [
  BaAppPicturePipe,
  BaKameleonPicturePipe,
  BaProfilePicturePipe,
  SnakeToRegularPipe,
  MomentTimezonePipe,
];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService,
  ApiService,
  ApiWaiverService,
  SystemCompanyService,
  {provide: DateAdapter, useClass: MomentDateAdapter},
  {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
  JsonToCsvService,
];

const NGA_VALIDATORS = [
  EmailValidator,
  EqualPasswordsValidator
];

const NGA_MODULES = [
  NgxUploaderModule,
  BsDropdownModule,
  Ng2TableModule,
  PaginationModule,
  TabsModule,
  NguiDatetimePickerModule,
  MatDatepickerModule,
  MatAutocompleteModule,
  BsDatepickerModule,
  TypeaheadModule,
  MomentModule,
  QuillModule,
  MatFormFieldModule,
  MatInputModule,
  MatProgressSpinnerModule,
  FlexLayoutModule,
  NgSelectModule,
  ImageCropperModule,
  MatSelectModule,
  MatCheckboxModule

];

@NgModule({
  declarations: [
    ...NGA_COMPONENTS,
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ModalModule.forRoot(),
    TimepickerModule.forRoot(),
    DragulaModule.forRoot(),
    ReactiveFormsModule,
    ...NGA_MODULES,
  ],
  exports: [
    ...NGA_PIPES,
    ...NGA_DIRECTIVES,
    ...NGA_COMPONENTS,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    FroalaEditorModule,
    FroalaViewModule,
    DragulaModule,
    ...NGA_MODULES,
  ],
  entryComponents: [
    InvoiceItemEditModalComponent,
    AddPaymentModalComponent,
    AddGiftVoucherModalComponent,
    AddClientModalComponent,
    AddClientContactModal,
    PriceElementItemComponent,
    PriceElementModal,
  ]
})
export class NgaModule {
  constructor() {
    // defineLocale('en-gb', enGb);
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES
      ],
    };
  }
}
