import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'snakeToRegular',
    pure: true
  }
)
export class SnakeToRegularPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return value.replace(new RegExp('_', 'g'), ' ');
  }

}
