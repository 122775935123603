import {UUID} from '../app/utils/Utils';
import {MembershipType} from './MembershipType';
import {Contact} from './Contact';
import {Course} from './Course';
import {Serializable} from './Serializable';
import {MembershipTypeA} from './MembershipTypeA';


export class Membership implements Serializable<Membership> {
  id: string | number = UUID();
  membershipType: MembershipType;
  customer: Contact;
  expiryDate: string;
  members: Contact[] = [];
  startDate: string;
  status: MembershipStatus = MembershipStatus.onHold;
  paymentMethod: MembershipPaymentMethod = MembershipPaymentMethod.oneOff;
  notes: string;

  getCourseDiscount(course: Course) {
    return this.membershipType.getCourseDiscount(course);
  }

  constructor() {
  }

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    if (input.membershipType && input.membershipType.type) {
      if (input.membershipType.type === 'membershipA') {
        this.membershipType = new MembershipTypeA().deserialize(input.membershipType);
      }
    }
    if (input.customer) {
      this.customer = input.customer;
    }
    this.expiryDate = input.expiryDate;
    this.status = input.status;
    this.paymentMethod = input.paymentMethod;
    this.notes = input.notes;
    return this;
  }

}

export enum MembershipStatus {
  active = 'active',
  onHold = 'on-hold',
  expired = 'expired',
  cancelled = 'cancelled',
  chasing = 'chasing',
  suspended = 'suspended',
}

export enum MembershipPaymentMethod {
  oneOff = 'one-off',
  monthly = 'monthly'
}
