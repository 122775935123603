import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";

import { environment } from "../../environments/environment";
import { Course } from "../../models/Course";
import { StaffMember } from "../../models/StaffMember";
import { Resource } from "../../models/Resource";
import { PhysicalLocation } from "../../models/Location";
import { ChildOf } from "../../models/ChildOf";
import { Friendship } from "../../models/Friendship";
import { Company } from "../../models/Company";
import { Enquiry } from "../../models/Enquiry";
import { Country } from "../../models/Country";
import { BillingAddress } from "../../models/BillingAddress";
import { CacheService } from "./cache/cache.service";
import { Contact } from "../../models/Contact";
import { CourseSession } from "../../models/CourseSession";
import { CourseRun } from "../../models/CourseRun";
import { CourseRunBooking } from "../../models/CourseRunBooking";
import { CourseRunCollection } from "../../models/Collections/CourseRunCollection";
import { ContactCollection } from "../../models/Collections/ContactCollection";
import { ResourceCollection } from "../../models/Collections/ResourceCollection";
import { CourseRunBookingCollection } from "../../models/Collections/CourseRunBookingCollection";
import { EnquiryCollection } from "../../models/Collections/EnquiryCollection";
import { WaitingListRequestCollection } from "../../models/Collections/WaitingListRequestCollection";
import { StaffMemberCollection } from "../../models/Collections/StaffMemberCollection";
import { LocationCollection } from "../../models/Collections/LocationCollection";
import { ChildOfCollection } from "../../models/Collections/ChildOfCollection";
import { CompanyCollection } from "../../models/Collections/CompanyCollection";
import { CourseCollection } from "../../models/Collections/CourseCollection";
import { BookingOrder } from "../../models/BookingOrder";
import { BookingOrderCollection } from "../../models/Collections/BookingOrderCollection";
import { DiscountCode } from "../../models/DiscountCode";
import { InvoiceCollection } from "../../models/Collections/InvoiceCollection";
import { Invoice } from "../../models/Invoice";
import { CancellationPolicy } from "../../models/CancellationPolicy";
import { CancellationPolicyCollection } from "../../models/Collections/CancellationPolicyCollection";
import { MembershipTypeCollection } from "../../models/Collections/MembershipTypeCollection";
import { MembershipType } from "../../models/MembershipType";
import { OfferCollection } from "../../models/Collections/OfferCollection";
import { Offer } from "../../models/Offer";
import { SalesCategoryCollection } from "../../models/Collections/SalesCategoryCollection";
import { SalesCategory } from "../../models/SalesCategory";
import { ResourceTypeCollection } from "../../models/Collections/ResourceTypeCollection";
import { PaymentCollection } from "../../models/Collections/PaymentCollection";
import { HttpClient } from "@angular/common/http";
import { toHttpParams } from "../utils/Utils";
import { CourseTypeCollection } from "../../models/Collections/CourseTypeCollection";
import { map, shareReplay, tap } from "rxjs/operators";
import { Currency } from "../../models/Currency";
import { Payment } from "../../models/Payment";
import { CourseSessionCollection } from "../../models/Collections/CourseSessionCollection";
import { CustomerCompanyCollection } from "../../models/Collections/CustomerCompanyCollection";
import { CustomerCompany } from "../../models/CustomerCompany";
import { ResourceType } from "../../models/ResourceType";
import { DiscountCodeCollection } from "../../models/Collections/DiscountCodeCollection";
import { GiftVoucherCollection } from "../../models/Collections/GiftVoucherCollection";
import { GiftVoucher } from "../../models/GiftVoucher";
import { BookingParticipant } from "../../models/BookingParticipant";
import { BookingParticipantCollection } from "../../models/Collections/BookingParticipantCollection";
import { MembershipTypeA } from "../../models/MembershipTypeA";
import { MembershipCollection } from "../../models/Collections/MembershipCollection";
import { Membership } from "../../models/Membership";
import { Moment } from "moment";
import { FriendshipCollection } from "../../models/Collections/FriendshipCollection";
import { EmailTemplateCollection } from "../../models/Collections/EmailTemplateCollection";
import { EmailTemplate } from "../../models/EmailTemplate";
import { GiftVoucherImageCollection } from "../../models/Collections/GiftVoucherImageCollection";
import { GiftVoucherImage } from "../../models/GiftVoucherImage";
import { Package } from "models/Package";
import { PackageCustomer } from "models/PackageCustomer";

@Injectable()
export class ApiService {
  courses$: Observable<Course[]>;
  private _courses: BehaviorSubject<Course[]>;
  private baseUrl: string;

  constructor(private authHttp: HttpClient, private cacheSvc: CacheService) {
    this.baseUrl = environment.apiUrl;
    this._courses = <BehaviorSubject<Course[]>>new BehaviorSubject([]);
    this.courses$ = this._courses.asObservable();
  }

  getApiUrl() {
    return this.baseUrl;
  }

  createCourse(course: Course): Observable<Course> {
    const structure = {
      name: null,
      priceElements: [{ description: null, applyTax: null, amount: null }],
      salesCategory: { id: null },
      type: { id: null },
      generator: null,
      location: { id: null },
      billingCompany: { id: null },
      cancellationPolicy: { id: null },
      generatorDataItems: [
        { startTime: null, duration: null, weekday: null, weekIdx: null },
      ],
      resourceRequirements: [
        {
          resource: { id: null },
        },
      ],
      subCourses: [
        {
          title: null,
          description: null,
        },
      ],
      enabled: null,
      favourite: null,
      pdfBrochureFilePath: null,
      groupCourse: null,
      slotDuration: null,
      notes: null,
      maxNumbers: null,
      calendarColor: null,
      clientCanCancel: null,
      waitingList: null,
      inHouseCertificate: null,
      preRequirements: null,
      visibility: null,
      mailchimpWaiver: null
    };

    const result = this.processStructure(structure, course);

    return this.authHttp.post<Course>(this.baseUrl + "/courses", result);
  }

  getCourses(params: any = null): Observable<CourseCollection> {
    return this.authHttp.get<CourseCollection>(`${this.baseUrl}/courses`, {
      params: toHttpParams(params),
    });
  }

  getCourse(id: number): Observable<Course> {
    return this.authHttp.get<Course>(this.baseUrl + "/courses/" + id);
  }

  updateCourse(course: Course) {
    const structure = {
      name: null,
      priceElements: [
        { id: null, description: null, applyTax: null, amount: null },
      ],
      salesCategory: { id: null },
      type: { id: null },
      generator: null,
      location: { id: null },
      billingCompany: { id: null },
      cancellationPolicy: { id: null },
      generatorDataItems: [
        {
          id: null,
          startTime: null,
          duration: null,
          weekday: null,
          weekIdx: null,
        },
      ],
      resourceRequirements: [
        {
          id: null,
          resource: { id: null },
        },
      ],
      subCourses: [
        {
          id: null,
          title: null,
          description: null,
        },
      ],
      enabled: null,
      favourite: null,
      pdfBrochureFilePath: null,
      groupCourse: null,
      slotDuration: null,
      notes: null,
      maxNumbers: null,
      calendarColor: null,
      clientCanCancel: null,
      waitingList: null,
      inHouseCertificate: null,
      preRequirements: null,
      visibility: null,
      mailchimpWaiver: null,
      waiverId:null
    };

    const result = this.processStructure(structure, course);
    return this.authHttp.patch<Course>(
      this.baseUrl + "/courses/" + course.id,
      result
    );
  }

  getEvents(
    startDate: Moment,
    endDate: Moment,
    company: Company,
    location: PhysicalLocation = null
  ): Observable<CourseSession[]> {
    let params: { jsonQuery?: any; "no-page"?: boolean } = {
      jsonQuery: {
        $and: [
          {
            startDate: {
              $lte: endDate.locale("en").format("YYYY-MM-DD HH:mm"),
            },
          },
          {
            endDate: {
              $gte: startDate.locale("en").format("YYYY-MM-DD HH:mm"),
            },
          },
        ],
        "courseRun.course.billingCompany.id": null,
      },
      "no-page": true,
    };

    if (company) {
      params.jsonQuery = {
        ...params.jsonQuery,
        "courseRun.course.billingCompany.id": company.id,
      };
    }

    if (location && location.id > 0) {
      params.jsonQuery = {
        ...params.jsonQuery,
        "courseRun.course.location.id": location.id,
      };
    }

    const parsedParams = toHttpParams(params);
    return this.authHttp
      .get<CourseSessionCollection>(`${this.baseUrl}/courseSessions`, {
        params: parsedParams,
      })
      .pipe(map((courseSessionCollection) => courseSessionCollection.data));
  }

  updateEvent(courseEvent: CourseSession) {
    return this.authHttp.patch<CourseSession>(
      `${this.baseUrl}/courseSessions/${courseEvent.id}`,
      courseEvent
    );
  }

  getStaffs(params: any): Observable<StaffMemberCollection> {
    return this.authHttp.get<StaffMemberCollection>(
      this.baseUrl + "/staff_members",
      { params: toHttpParams(params) }
    );
  }

  getStaff(id: number): Observable<StaffMember> {
    return this.authHttp.get<StaffMember>(
      this.baseUrl + "/staff_members/" + id
    );
  }

  createStaff(staffMember: StaffMember): Observable<StaffMember> {
    return this.authHttp.post<StaffMember>(
      `${this.baseUrl}/staff_members/`,
      staffMember
    );
  }

  updateStaff(staffMember: StaffMember): Observable<StaffMember> {
    return this.authHttp.patch<StaffMember>(
      `${this.baseUrl}/staff_members/${staffMember.id}`,
      staffMember
    );
  }

  createCourseRun(courseRun: CourseRun): Observable<CourseRun> {
    const structure = {
      course: { id: null },
      courseSessions: [
        {
          startDate: null,
          endDate: null,
          assignedStaffMembers: [{ id: null }],
        },
      ],
      priceElements: [
        {
          description: null,
          amount: null,
          taxPercentage: null,
          applyTax: null,
        },
      ],
    };

    const result = this.processStructure(structure, courseRun);
    return this.authHttp.post<CourseRun>(`${this.baseUrl}/courseRuns`, result);
  }

  updateCourseRun(courseRun: CourseRun): Observable<CourseRun> {
    return this.authHttp.patch<CourseRun>(
      `${this.baseUrl}/courseRuns/${courseRun.id}`,
      courseRun
    );
  }

  getBookings(params: any = null): Observable<CourseRunBookingCollection> {
    return this.authHttp.get<CourseRunBookingCollection>(
      `${this.baseUrl}/courseRunBookings`,
      { params: toHttpParams(params) }
    );
  }
  getCustomerBookings(customerId)
  {
    return this.authHttp.get<any>(
      `${this.baseUrl}/bookingOrders/customer/${customerId}`      
    );
  }
  getCourseRuns(params: any = null): Observable<CourseRunCollection> {
    return this.authHttp.get<CourseRunCollection>(
      `${this.baseUrl}/courseRuns`,
      { params: toHttpParams(params) }
    );
  }

  getResources(params: any): Observable<ResourceCollection> {
    return this.authHttp.get<ResourceCollection>(`${this.baseUrl}/resources`, {
      params: toHttpParams(params),
    });
  }

  getLocations(params: any = null): Observable<LocationCollection> {
    return this.authHttp
      .get<LocationCollection>(`${this.baseUrl}/locations`, {
        params: toHttpParams(params),
      })
      .pipe(shareReplay(1));
  }

  getResourceTypes(params: any = null): Observable<ResourceTypeCollection> {
    return this.authHttp.get<ResourceTypeCollection>(
      `${this.baseUrl}/resourceTypes`,
      { params: toHttpParams(params) }
    );
  }

  getSalesCategories(params: any = null): Observable<SalesCategoryCollection> {
    return this.authHttp.get<SalesCategoryCollection>(
      `${this.baseUrl}/salesCategories`,
      { params: toHttpParams(params) }
    );
  }

  getContacts(params: any): Observable<ContactCollection> {
    return this.authHttp.get<ContactCollection>(`${this.baseUrl}/contacts`, {
      params: toHttpParams(params),
    });
  }

  getCourseTypes(params: any): Observable<CourseTypeCollection> {
    return this.authHttp.get<CourseTypeCollection>(
      `${this.baseUrl}/courseTypes`,
      { params: toHttpParams(params) }
    );
  }

  getLocation(id: number | string): Observable<PhysicalLocation> {
    return this.authHttp.get<PhysicalLocation>(
      `${this.baseUrl}/locations/${id}`
    );
  }

  updateLocation(location: PhysicalLocation): Observable<PhysicalLocation> {
    return this.authHttp.patch<PhysicalLocation>(
      `${this.baseUrl}/locations/${location.id}`,
      location
    );
  }

  createLocation(location: PhysicalLocation): Observable<PhysicalLocation> {
    return this.authHttp.post<PhysicalLocation>(
      `${this.baseUrl}/locations/`,
      location
    );
  }

  getResource(id: any): Observable<Resource> {
    return this.authHttp.get<Resource>(this.baseUrl + "/resources/" + id);
  }

  createResource(resource: Resource): Observable<Resource> {
    return this.authHttp.post<Resource>(`${this.baseUrl}/resources/`, resource);
  }

  updateResource(resource: Resource): Observable<Resource> {
    return this.authHttp.patch<Resource>(
      `${this.baseUrl}/resources/${resource.id}`,
      resource
    );
  }

  getCustomers(params: any = null): Observable<ContactCollection> {
    params = {
      ...params,
      order: "firstName",
    };

    return this.authHttp.get<ContactCollection>(this.baseUrl + "/contacts", {
      params: toHttpParams(params),
    });
  }

  getContact(id: any): Observable<Contact> {
    return this.authHttp.get<Contact>(this.baseUrl + "/contacts/" + id);
  }

  updateContact(contact: Contact): Observable<Contact> {
    return this.authHttp.patch<Contact>(
      `${this.baseUrl}/contacts/${contact.id}`,
      contact
    );
  }

  createContact(contact: Contact): Observable<Contact> {
    return this.authHttp.post<Contact>(`${this.baseUrl}/contacts`, contact);
  }

  getMeChildren(): Observable<ChildOfCollection> {
    return this.authHttp.get<ChildOfCollection>(`${this.baseUrl}/me/children`);
  }

  getMePartners(): Observable<Contact[]> {
    return this.authHttp.get<Contact[]>(`${this.baseUrl}/me/partners`);
  }

  getMeFriendships(): Observable<Friendship[]> {
    return this.authHttp.get<Friendship[]>(`${this.baseUrl}/me/friendships`);
  }

  getMeFriendshipsWithMe(): Observable<Friendship> {
    return this.authHttp.get<Friendship>(
      `${this.baseUrl}/me/friendshipsWithMe`
    );
  }

  getCountries(): Observable<Country[]> {
    const countries = this.cacheSvc.getItem("countries");

    if (countries) {
      return of(countries);
    }

    const params = {
      "no-page": true,
      order: "name",
    };

    //we are going to create a cache of the countries as these are going to change only so often
    return this.authHttp
      .get<Country[]>(`${this.baseUrl}/public/countries`, {
        params: toHttpParams(params),
      })
      .pipe(tap((countries) => this.cacheSvc.saveItem("countries", countries)));
  }

  getCompanies(params: any = null): Observable<CompanyCollection> {
    return this.authHttp.get<CompanyCollection>(`${this.baseUrl}/companies`, {
      params: toHttpParams(params),
    });
  }

  updateCompany(company: Company): Observable<Company> {
    return this.authHttp.patch<Company>(
      `${this.baseUrl}/companies/${company.id}`,
      company
    );
  }

  createCompany(company: Company): Observable<Company> {
    return this.authHttp.post<Company>(`${this.baseUrl}/companies`, company);
  }

  getCompany(id: any) {
    return this.authHttp.get<Company>(`${this.baseUrl}/companies/${id}`);
  }

  getCurrencies(): Observable<Currency[]> {
    return this.authHttp.get<Currency[]>(`${this.baseUrl}/public/currencies`);
  }

  getEnquiries(params: any): Observable<EnquiryCollection> {
    return this.authHttp.get<EnquiryCollection>(`${this.baseUrl}/enquiries`, {
      params: toHttpParams(params),
    });
  }

  getEnquiry(id: any): Observable<Enquiry> {
    return this.authHttp.get<Enquiry>(`${this.baseUrl}/enquiries/${id}`);
  }

  flagEnquiryAsAnswered(enquiry: Enquiry): Observable<Enquiry> {
    return this.authHttp.put<Enquiry>(
      `${this.baseUrl}/enquiries/${enquiry.id}/answered`,
      {}
    );
  }

  getWaitingLists(params: any): Observable<WaitingListRequestCollection> {
    return this.authHttp.get<WaitingListRequestCollection>(
      `${this.baseUrl}/waitingListRequests`,
      { params: toHttpParams(params) }
    );
  }

  // getProfile() {
  //   return this.authHttp
  //     .get(`${this.baseUrl}/me`)
  //     .map((res: ) => {
  //       return <Contact>res.json();
  //     })
  // }

  searchAndAddContactFriend(
    contactId,
    email,
    userName
  ): Observable<Friendship> {
    return this.authHttp.post<Friendship>(
      `${this.baseUrl}/contacts/${contactId}/friends/search`,
      { email, userName }
    );
  }

  addContactFriend(contactId, friend): Observable<Friendship> {
    return this.authHttp.post<Friendship>(
      `${this.baseUrl}/contacts/${contactId}/friends`,
      friend
    );
  }

  addContactPartner(contactId, partner): Observable<Contact> {
    return this.authHttp.post<Contact>(
      `${this.baseUrl}/contacts/${contactId}/partners`,
      partner
    );
  }

  addContactChild(contactId, child): Observable<ChildOf> {
    return this.authHttp.post<ChildOf>(
      `${this.baseUrl}/contacts/${contactId}/children`,
      child
    );
  }

  updateCustomerBillingAddress(
    contactId: number,
    value: any
  ): Observable<BillingAddress> {
    return this.authHttp.patch<BillingAddress>(
      `${this.baseUrl}/contacts/${contactId}/billingAddress`,
      value
    );
  }

  getCourseRunBooking(id: number): Observable<CourseRunBooking> {
    return this.authHttp.get<CourseRunBooking>(
      `${this.baseUrl}/courseRunBookings/${id}`
    );
  }

  // createCourseRunBooking(courseRun: CourseRun, customer: Contact, participants: Contact[] = null, groupParticipantsCount: number = null) {
  //   return this.authHttp
  //     .post(`${this.baseUrl}/courseRuns/${courseRun.id}/book`, {customer, participants, groupParticipantsCount})
  //     .map((res: ) => {
  //         return res.json()
  //       }
  //     );
  // }

  getPaymentMethods(): Observable<Array<string>> {
    return of([
      "sagepay",
      "invoice",
      "card",
      "amex",
      "cash",
      "cheque",
      "bacs",
      "standingOrder",
      "stripe",
      "giftVoucher",
      "free",
    ]);
  }

  createPayment(
    order: BookingOrder,
    payment: Partial<Payment>
  ): Observable<Payment> {
    return this.authHttp.post<Payment>(
      `${this.baseUrl}/bookingOrders/${order.id}/payments`,
      payment
    );
  }

  createInvoicePayment(
    invoice: Invoice,
    payment: Payment
  ): Observable<Payment> {
    return this.authHttp.post<Payment>(
      `${this.baseUrl}/invoices/${invoice.id}/payments`,
      payment
    );
  }

  geContactFriendships(
    contact: Contact,
    params = null
  ): Observable<Friendship[]> {
    return this.authHttp.get<Friendship[]>(
      `${this.baseUrl}/contacts/${contact.id}/friendships`,
      { params: toHttpParams(params) }
    );
  }

  getContactChildren(
    contact: Contact,
    params = null
  ): Observable<ChildOfCollection> {
    return this.authHttp.get<ChildOfCollection>(
      `${this.baseUrl}/contacts/${contact.id}/children`,
      { params: toHttpParams(params) }
    );
  }

  getContactPartners(
    contact: Contact,
    params = null
  ): Observable<ContactCollection> {
    return this.authHttp.get<ContactCollection>(
      `${this.baseUrl}/contacts/${contact.id}/partners`,
      { params: toHttpParams(params) }
    );
  }

  getFriendships(params: any = null) {
    return this.authHttp.get<FriendshipCollection>(
      `${this.baseUrl}/friendships`,
      { params: toHttpParams(params) }
    );
  }

  // getContactPendingOrder(contact: Contact): Observable<BookingOrder> {
  //
  //   const params = {
  //     jsonQuery: {
  //       'customer.id': contact.id,
  //       status: 'pending'
  //     }
  //   };
  //
  //   return this.authHttp
  //     .get<BookingOrderCollection>(`${this.baseUrl}/bookingOrders`, {params: toHttpParams(params)})
  //     .map((bookingOrderCollection: BookingOrderCollection) => {
  //       if (bookingOrderCollection.data.length == 0) {
  //         return null;
  //       } else {
  //         return bookingOrderCollection.data[0]
  //       }
  //     })
  // }

  // createOrder(customer: Contact, company: Company) {
  //   const params = {
  //     customer,
  //     company
  //   };
  //
  //   return this.authHttp
  //     .post(`${this.baseUrl}/bookingOrders`, params)
  //     .map((res: ) => <BookingOrder>res.json())
  // }

  addBookingToOrder(
    bookingOrder: BookingOrder,
    courseRunBooking: CourseRunBooking
  ): Observable<BookingOrder> {
    const params = {
      courseRun: courseRunBooking.courseRun,
      participants: courseRunBooking.participants,
      groupParticipantsCount: courseRunBooking.groupParticipantsCount,
      discountCode: courseRunBooking.discountCode,
      packageCourse: courseRunBooking.packageCourse,
      packageCustomer: courseRunBooking.packageCustomer,
    };
    return this.authHttp.post<BookingOrder>(
      `${this.baseUrl}/bookingOrders/${bookingOrder.id}/bookings`,
      params
    );
  }

  getStaffPendingBookingOrder(): Observable<BookingOrder> {
    return this.authHttp.get<BookingOrder>(
      `${this.baseUrl}/staffProfile/pendingOrder`
    );
  }

  deleteStaffOrder(): Observable<{ status: string }> {
    return this.authHttp.delete<{ status: string }>(
      `${this.baseUrl}/staffProfile/pendingOrder`
    );
  }

  checkDiscountAgainstCourseRun(
    courseRun: CourseRun,
    discountCode: string
  ): Observable<DiscountCode> {
    return this.authHttp.post<DiscountCode>(
      `${this.baseUrl}/courseRuns/${courseRun.id}/checkDiscountCodeApplicability`,
      { discountCode }
    );
  }

  getOrders(params: any): Observable<BookingOrderCollection> {
    return this.authHttp.get<BookingOrderCollection>(
      `${this.baseUrl}/bookingOrders`,
      { params: toHttpParams(params) }
    );
  }

  getInvoices(params: any = null): Observable<InvoiceCollection> {
    return this.authHttp.get<InvoiceCollection>(`${this.baseUrl}/invoices`, {
      params: toHttpParams(params),
    });
  }

  getInvoicesFiltered(params: any = null): Observable<InvoiceCollection> {
    return this.authHttp.get<InvoiceCollection>(
      `${this.baseUrl}/invoices/filtered`,
      { params: toHttpParams(params) }
    );
  }

  getInvoice(id: any): Observable<Invoice> {
    return this.authHttp.get<Invoice>(`${this.baseUrl}/invoices/${id}`);
  }

  deleteCourse(id: number): Observable<{ status: string }> {
    return this.authHttp.delete<{ status: string }>(
      `${this.baseUrl}/courses/${id}`
    );
  }

  updateInvoice(invoice: Invoice): Observable<{ status: string }> {
    return this.authHttp.patch<{ status: string }>(
      `${this.baseUrl}/invoices/${invoice.id}`,
      invoice
    );
  }

  getCancellationPolicy(id: any): Observable<CancellationPolicy> {
    return this.authHttp.get<CancellationPolicy>(
      `${this.baseUrl}/cancellationPolicies/${id}`
    );
  }

  getCancellationPolicies(
    params: any
  ): Observable<CancellationPolicyCollection> {
    return this.authHttp.get<CancellationPolicyCollection>(
      `${this.baseUrl}/cancellationPolicies/`,
      { params: toHttpParams(params) }
    );
  }

  createCancellationPolicy(
    cancellationPolicy: CancellationPolicy
  ): Observable<CancellationPolicy> {
    return this.authHttp.post<CancellationPolicy>(
      `${this.baseUrl}/cancellationPolicies/`,
      cancellationPolicy
    );
  }

  updateCancellationPolicy(
    cancellationPolicy: CancellationPolicy
  ): Observable<CancellationPolicy> {
    return this.authHttp.patch<CancellationPolicy>(
      `${this.baseUrl}/cancellationPolicies/${cancellationPolicy.id}`,
      cancellationPolicy
    );
  }

  getMembershipTypes(params: any): Observable<MembershipTypeCollection> {
    return this.authHttp.get<MembershipTypeCollection>(
      `${this.baseUrl}/membershipTypes`,
      { params: toHttpParams(params) }
    );
  }

  getMembership(id: any, params: any = null): Observable<Membership> {
    return this.authHttp
      .get<Membership>(`${this.baseUrl}/memberships/${id}`, {
        params: toHttpParams(params),
      })
      .pipe(
        map((membershipData) => {
          const membership = new Membership();
          Object.assign(membership, membershipData);
          return membership;
        })
      );
  }

  getMembershipA(id: any, params: any = null): Observable<MembershipTypeA> {
    return this.authHttp
      .get<MembershipTypeA>(`${this.baseUrl}/membershipTypesA/${id}`, {
        params: toHttpParams(params),
      })
      .pipe(
        map((membershipTypeA) => {
          return new MembershipTypeA().deserialize(membershipTypeA);
        })
      );
  }

  // addInvoiceItem(invoiceId: any, item: InvoiceItem) {
  //   return this.authHttp
  //     .post(`${this.baseUrl}/invoices/${invoiceId}/invoiceItems`, item)
  //     .map((res: ) => <InvoiceItem>res.json());
  // }

  getOffers(params: any = null): Observable<OfferCollection> {
    return this.authHttp.get<OfferCollection>(`${this.baseUrl}/offers`, {
      params: toHttpParams(params),
    });
  }

  getOffer(id: any, params: any = null): Observable<Offer> {
    return this.authHttp.get<Offer>(`${this.baseUrl}/offers/${id}`, {
      params: toHttpParams(params),
    });
  }

  createOffer(offer: Offer): Observable<Offer> {
    return this.authHttp.post<Offer>(`${this.baseUrl}/offers`, offer);
  }

  updateOffer(offer: Offer): Observable<Offer> {
    return this.authHttp.patch<Offer>(
      `${this.baseUrl}/offers/${offer.id}`,
      offer
    );
  }

  createMembershipType(membership: any): Observable<MembershipType> {
    return this.authHttp.post<MembershipType>(
      `${this.baseUrl}/membershipTypes`,
      membership
    );
  }

  updateMembershipType(membership: any): Observable<MembershipType> {
    return this.authHttp.patch<MembershipType>(
      `${this.baseUrl}/membershipTypes`,
      membership
    );
  }

  getSalesCategory(id: number, params: any = null): Observable<SalesCategory> {
    return this.authHttp.get<SalesCategory>(
      `${this.baseUrl}/salesCategories/${id}`,
      { params: toHttpParams(params) }
    );
  }

  createSalesCategory(salesCategory: SalesCategory): Observable<SalesCategory> {
    return this.authHttp.post<SalesCategory>(
      `${this.baseUrl}/salesCategories/`,
      salesCategory
    );
  }

  updateSalesCategory(salesCategory: SalesCategory): Observable<SalesCategory> {
    return this.authHttp.patch<SalesCategory>(
      `${this.baseUrl}/salesCategories/${salesCategory.id}`,
      salesCategory
    );
  }

  deleteSalesCategory(
    salesCategory: SalesCategory
  ): Observable<{ status: string }> {
    return this.authHttp.delete<{ status: string }>(
      `${this.baseUrl}/salesCategories/${salesCategory.id}`
    );
  }

  deleteResource(resource: Resource): Observable<{ status: string }> {
    return this.authHttp.delete<{ status: string }>(
      `${this.baseUrl}/resources/${resource.id}`
    );
  }

  deleteOffer(offer: Offer): Observable<{ status: string }> {
    return this.authHttp.delete<{ status: string }>(
      `${this.baseUrl}/offers/${offer.id}`
    );
  }

  getPayments(params = null): Observable<PaymentCollection> {
    return this.authHttp.get<PaymentCollection>(`${this.baseUrl}/payments`, {
      params: toHttpParams(params),
    });
  }

  resendConfirmationEmail(courseRunBooking: CourseRunBooking) {
    return this.authHttp.get<{ status: string }>(
      `${this.baseUrl}/courseRunBookings/${courseRunBooking.id}/resendEmail`
    );
  }

  getCourseSession(
    id: number | string,
    params: any = null
  ): Observable<CourseSession> {
    return this.authHttp.get<CourseSession>(
      `${this.baseUrl}/courseSessions/${id}`,
      { params: toHttpParams(params) }
    );
  }

  getCourseRun(id: number | string, params: any = null): Observable<CourseRun> {
    return this.authHttp.get<CourseRun>(`${this.baseUrl}/courseRuns/${id}`, {
      params: toHttpParams(params),
    });
  }

  getOrder(id: number | string, params: any = null): Observable<BookingOrder> {
    return this.authHttp.get<BookingOrder>(
      `${this.baseUrl}/bookingOrders/${id}`,
      { params: toHttpParams(params) }
    );
  }

  getInvoicePDF(invoice: Invoice): Observable<Blob> {
    return this.authHttp.get(`${this.baseUrl}/invoices/${invoice.id}/pdf`, {
      responseType: "blob",
    });
  }

  getCustomerCompanies(params: any): Observable<CustomerCompanyCollection> {
    return this.authHttp.get<CustomerCompanyCollection>(
      `${this.baseUrl}/customerCompanies`,
      { params: toHttpParams(params) }
    );
  }

  getCustomerCompany(id: any, params: any = null): Observable<CustomerCompany> {
    return this.authHttp.get<CustomerCompany>(
      `${this.baseUrl}/customerCompanies/${id}`,
      { params: toHttpParams(params) }
    );
  }

  createCustomerCompany(company: CustomerCompany): Observable<CustomerCompany> {
    return this.authHttp.post<CustomerCompany>(
      `${this.baseUrl}/customerCompanies/`,
      company
    );
  }

  updateCustomerCompany(company: CustomerCompany): Observable<CustomerCompany> {
    return this.authHttp.patch<CustomerCompany>(
      `${this.baseUrl}/customerCompanies/${company.id}`,
      company
    );
  }

  getResourceType(id, params = null): Observable<ResourceType> {
    return this.authHttp.get<ResourceType>(
      `${this.baseUrl}/resourceTypes/${id}`,
      { params: toHttpParams(params) }
    );
  }

  createResourceType(resourceType: ResourceType): Observable<ResourceType> {
    return this.authHttp.post<ResourceType>(
      `${this.baseUrl}/resourceTypes/`,
      resourceType
    );
  }

  updateResourceType(resourceType: ResourceType): Observable<ResourceType> {
    return this.authHttp.patch<ResourceType>(
      `${this.baseUrl}/resourceTypes/${resourceType.id}`,
      resourceType
    );
  }

  deleteResourceType(
    resourceType: ResourceType
  ): Observable<{ status: boolean }> {
    return this.authHttp.delete<{ status: boolean }>(
      `${this.baseUrl}/resourceTypes/${resourceType.id}`
    );
  }

  applyOffer(bookingOrder: BookingOrder, offer: Offer) {
    return this.authHttp.post<BookingOrder>(
      `${this.baseUrl}/bookingOrders/${bookingOrder.id}/offer`,
      offer
    );
  }

  removeOfferFromOrder(bookingOrder: BookingOrder, offer: Offer) {
    return this.authHttp.delete<BookingOrder>(
      `${this.baseUrl}/bookingOrders/${bookingOrder.id}/offer/${offer.id}`
    );
  }

  getDiscountCodes(params: any = null): Observable<DiscountCodeCollection> {
    return this.authHttp.get<DiscountCodeCollection>(
      `${this.baseUrl}/discountCodes`,
      { params: toHttpParams(params) }
    );
  }

  getDiscountCode(
    id: number | string,
    params: any = null
  ): Observable<DiscountCode> {
    return this.authHttp.get<DiscountCode>(
      `${this.baseUrl}/discountCodes/${id}`,
      { params: toHttpParams(params) }
    );
  }

  createDiscountCode(discountCode: DiscountCode): Observable<DiscountCode> {
    return this.authHttp.post<DiscountCode>(
      `${this.baseUrl}/discountCodes`,
      discountCode
    );
  }

  updateDiscountCode(discountCode: DiscountCode): Observable<DiscountCode> {
    return this.authHttp.patch<DiscountCode>(
      `${this.baseUrl}/discountCodes/${discountCode.id}`,
      discountCode
    );
  }

  savePermissions(permissions: any) {
    return this.authHttp.post<any>(`${this.baseUrl}/permissions/`, permissions);
  }

  loadPermissions() {
    return this.authHttp.get<any>(`${this.baseUrl}/permissions`);
  }

  deleteCourseRun(courseRun: CourseRun): Observable<{ status: boolean }> {
    return this.authHttp.delete<{ status: boolean }>(
      `${this.baseUrl}/courseRuns/${courseRun.id}`
    );
  }

  getGiftVouchers(params: any = null): Observable<GiftVoucherCollection> {
    return this.authHttp.get<GiftVoucherCollection>(
      `${this.baseUrl}/giftVouchers`,
      { params: toHttpParams(params) }
    );
  }

  getGiftVoucher(
    id: number | string,
    params: any = null
  ): Observable<GiftVoucher> {
    return this.authHttp.get<GiftVoucher>(
      `${this.baseUrl}/giftVouchers/${id}`,
      { params: toHttpParams(params) }
    );
  }

  updateGiftVoucher(
    giftVoucher: Partial<GiftVoucher>
  ): Observable<GiftVoucher> {
    return this.authHttp.patch<GiftVoucher>(
      `${this.baseUrl}/giftVouchers/${giftVoucher.id}`,
      giftVoucher
    );
  }

  giftVoucherAssignAndRedeem(
    giftVoucher: Partial<GiftVoucher>,
    contact: Partial<Contact>
  ): Observable<GiftVoucher> {
    return this.authHttp.post<GiftVoucher>(
      `${this.baseUrl}/giftVouchers/${giftVoucher.id}/assignedTo`,
      contact
    );
  }

  createGiftVoucher(giftVoucher: GiftVoucher): Observable<GiftVoucher> {
    return this.authHttp.post<GiftVoucher>(
      `${this.baseUrl}/giftVouchers}`,
      giftVoucher
    );
  }

  deleteGiftVoucher(giftVoucher: GiftVoucher): Observable<{ status: boolean }> {
    return this.authHttp.delete<{ status: boolean }>(
      `${this.baseUrl}/giftVouchers/${giftVoucher.id}`
    );
  }

  /*
  create a pending order
  */
  createStaffOrder(
    customer: Contact,
    company: Company,
    customerCompany: CustomerCompany = null
  ) {
    return this.authHttp.post<BookingOrder>(
      `${this.baseUrl}/staffProfile/pendingOrder`,
      { customer, company, customerCompany }
    );
  }

  addGiftVoucherToOrder(bookingOrder: BookingOrder, giftVoucher: GiftVoucher) {
    return this.authHttp.post<BookingOrder>(
      `${this.baseUrl}/bookingOrders/${bookingOrder.id}/giftVouchers`,
      giftVoucher
    );
  }

  getAvailableCredit(
    client: Contact,
    company: Company
  ): Observable<GiftVoucher[]> {
    return this.authHttp.get<GiftVoucher[]>(
      `${this.baseUrl}/contacts/${client.id}/availableCredit`,
      {
        params: toHttpParams({ companyId: company.id }),
      }
    );
  }

  getVoucherInfoFromCode(code: any): Observable<any> {
    return this.authHttp.get<any>(
      `${this.baseUrl}/public/giftVouchers/code/${code}`
    );
  }

  finishCustomerRegistration(
    id: number,
    email,
    password
  ): Observable<{ status: boolean; token: string }> {
    return this.authHttp.post<{ status: boolean; token: string }>(
      `${this.baseUrl}/public/finishCustomerRegistration`,
      {
        customerId: id,
        email,
        password,
      }
    );
  }

  redeemGiftVoucherCode(code) {
    return this.authHttp.post<{ status: boolean }>(
      `${this.baseUrl}/me/redeemVoucherCode`,
      { code }
    );
  }

  updateCourseSession(courseSession: any): Observable<any> {
    const structure = {
      assignedStaffMembers: [{ id: null }],
      assignedResources: [{ id: null }],
      startDate: null,
      endDate: null,
      requiredResources: [{ id: null }],
    };
    //
    const result = this.processStructure(structure, courseSession);
    console.log("structure ==>", courseSession.id, result);
    return this.authHttp.put<any>(
      `${this.baseUrl}/courseSessions/${courseSession.id}`,
      result
    );
  }

  updateBookingParticipant(participant: BookingParticipant) {
    return this.authHttp.patch<any>(
      `${this.baseUrl}/bookingParticipants/${participant.id}`,
      participant
    );
  }

  getBookingParticipants(
    params: any
  ): Observable<BookingParticipantCollection> {
    return this.authHttp.get<any>(`${this.baseUrl}/bookingParticipants/`, {
      params: toHttpParams(params),
    });
  }

  updateMembershipTypeA(
    membershipType: MembershipTypeA
  ): Observable<MembershipTypeA> {
    const structure = <MembershipTypeA>{
      freeCourses: [{ id: null }],
      discountedCourses: [{ id: null }],
      limitations: [
        {
          id: null,
          courses: [{ id: null }],
          limitInAdvance: null,
          limitMonday: null,
          limitFriday: null,
          limitSaturday: null,
          limitSunday: null,
          limitThursday: null,
          limitTuesday: null,
          limitWednesday: null,
        },
      ],
      duration: null,
      allCoursesDiscount: null,
      largerDiscount: null,
      grade: null,
      bookGuestYear: null,
    };

    const result = this.processStructure(structure, membershipType);
    return this.authHttp.patch<MembershipTypeA>(
      `${this.baseUrl}/membershipTypesA/${membershipType.id}`,
      result
    );
  }

  createMembershipTypeA(
    membershipType: MembershipTypeA
  ): Observable<MembershipTypeA> {
    return this.authHttp.post<MembershipTypeA>(
      `${this.baseUrl}/membershipTypesA`,
      membershipType
    );
  }

  getMemberships(params: any = null): Observable<MembershipCollection> {
    return this.authHttp.get<MembershipCollection>(
      `${this.baseUrl}/memberships/`,
      { params: toHttpParams(params) }
    );
  }

  createMembership(membership: Membership): Observable<Membership> {
    return this.authHttp.post<Membership>(
      `${this.baseUrl}/memberships/`,
      membership
    );
  }

  updateMembership(membership: Membership): Observable<Membership> {
    return this.authHttp.patch<Membership>(
      `${this.baseUrl}/memberships/${membership.id}`,
      membership
    );
  }

  checkCanBookWithMembership(
    contact: Contact,
    courseRun: CourseRun
  ): Observable<boolean | Membership> {
    return this.authHttp
      .post<boolean | Membership>(
        `${this.baseUrl}/contacts/${contact.id}/canBookWithMembership`,
        { courseRun: { id: courseRun.id } }
      )
      .pipe(
        map((booleanOrMembership) => {
          if (typeof booleanOrMembership === "boolean") {
            return booleanOrMembership;
          }

          return new Membership().deserialize(booleanOrMembership);
        })
      );
  }

  getShiftStartTime(params: any) {
    return this.authHttp.get(`${this.baseUrl}/shiftStartTimes`);
  }

  processStructure(structure, object) {
    return Object.keys(structure).reduce((obj, key) => {
      if (structure[key] === null) {
        obj[key] = object[key];
        return obj;
      }

      if (Array.isArray(structure[key])) {
        if (!Array.isArray(object[key])) {
          return obj;
        }

        obj[key] = object[key].map((item) => {
          return this.processStructure(structure[key][0], item);
        });
        return obj;
      }

      if (structure[key] instanceof Object) {
        if (!(object[key] instanceof Object)) {
          return obj;
        }

        obj[key] = this.processStructure(structure[key], object[key]);
        return obj;
      }
      return obj;
    }, {});
  }

  regenerateInvoice(invoice: Invoice): Observable<Invoice> {
    return this.authHttp.post<Invoice>(
      `${this.baseUrl}/invoices/${invoice.id}/regenerate`,
      {}
    );
  }

  resendGiftVoucher(id: any) {
    return this.authHttp.post(
      `${this.baseUrl}/giftVouchers/${id}/reSendByEmail`,
      {}
    );
  }

  getEmailTemplates(companyId): Observable<EmailTemplateCollection> {
    return this.authHttp.get<EmailTemplateCollection>(
      `${this.baseUrl}/emailTemplates?jsonQuery={"company.id":${companyId}}&no-page`
    );
  }

  updateEmailTemplate(emailTemplate: EmailTemplate) {
    return this.authHttp.patch<EmailTemplate>(
      `${this.baseUrl}/emailTemplates/${emailTemplate.id}`,
      emailTemplate
    );
  }

  getGiftVoucherImages(params = null): Observable<GiftVoucherImageCollection> {
    return this.authHttp.get<GiftVoucherImageCollection>(
      `${this.baseUrl}/images`,
      { params: toHttpParams(params) }
    );
  }

  getGiftVoucherImage(id: any): Observable<GiftVoucherImage> {
    return this.authHttp.get<GiftVoucherImage>(`${this.baseUrl}/images/${id}`);
  }

  updateGiftVoucherImage(
    giftVoucherImage: Partial<GiftVoucherImage>
  ): Observable<GiftVoucherImage> {
    const structure = {
      name: null,
      company: { id: null },
      newImage: null,
      sequence: null,
    };
    const result = this.processStructure(structure, giftVoucherImage);

    return this.authHttp.patch<GiftVoucherImage>(
      `${this.baseUrl}/images/${giftVoucherImage.id}`,
      result
    );
  }

  createGiftVoucherImage(
    giftVoucherImage: GiftVoucherImage
  ): Observable<GiftVoucherImage> {
    const structure = {
      name: null,
      company: { id: null },
      newImage: null,
      sequence: null,
    };
    const result = this.processStructure(structure, giftVoucherImage);
    return this.authHttp.post<GiftVoucherImage>(
      `${this.baseUrl}/images`,
      result
    );
  }

  addStaffAvailability(staffMemberId, data) {
    return this.authHttp.post<any>(
      `${this.baseUrl}/staffAvailability/${staffMemberId}`,
      data
    );
  }

  getStaffAvailability(eventId, filter) {
    return this.authHttp.post<any>(
      `${this.baseUrl}/staffAvailability/${eventId}/get`,
      filter
    );
  }

  updateStaffAvailability(staffMemberId, data) {
    return this.authHttp.put<any>(
      `${this.baseUrl}/staffAvailability/${staffMemberId}`,
      data
    );
  }

  deleteStaffAvailability(params) {
    const parsedParams = toHttpParams(params);
    return this.authHttp.delete<any>(`${this.baseUrl}/staffAvailability`, {
      params: parsedParams,
    });
  }

  getStaffsAvailability(params): Observable<any[]> {
    const parsedParams = toHttpParams(params);
    return this.authHttp
      .get<any>(`${this.baseUrl}/staffAvailability`, { params: parsedParams })
      .pipe(map((avail) => avail.data));
  }

  getStaffTimeLine(params): Observable<any[]> {
    return this.authHttp
      .post<any>(`${this.baseUrl}/staffAvailability/timeline`, params)
      .pipe(map((times) => times["data"]));
  }

  repeatAvailabilityStatus(staffMemberId, params): Observable<any[]> {
    return this.authHttp.post<any>(
      `${this.baseUrl}/staffAvailability/${staffMemberId}/repeat`,
      params
    );
  }
  getStaffEvents(
    startDate: Moment,
    endDate: Moment,
    company: Company,
    location: PhysicalLocation = null,
    staffId
  ): Observable<CourseSession[]> {
    console.log("staffId", staffId);
    let params: { jsonQuery?: any; "no-page"?: boolean } = {
      jsonQuery: {
        $and: [
          {
            startDate: {
              $lte: endDate.locale("en").format("YYYY-MM-DD HH:mm"),
            },
          },
          {
            endDate: {
              $gte: startDate.locale("en").format("YYYY-MM-DD HH:mm"),
            },
          },
          { "assignedStaffMembers.id": { $eq: staffId } },
          //{'instructor.id':{$eq:staffId}},
        ],
        "courseRun.course.billingCompany.id": null,
        //'instructor.id':staffId
      },
      "no-page": true,
    };

    if (company) {
      params.jsonQuery = {
        ...params.jsonQuery,
        "courseRun.course.billingCompany.id": company.id,
      };
    }

    if (location) {
      params.jsonQuery = {
        ...params.jsonQuery,
        "courseRun.course.location.id": location.id,
      };
    }

    const parsedParams = toHttpParams(params);
    return this.authHttp
      .get<CourseSessionCollection>(`${this.baseUrl}/courseSessions`, {
        params: parsedParams,
      })
      .pipe(map((courseSessionCollection) => courseSessionCollection.data));
  }

  //packages
  getPackages(): Observable<Package[]> {
    return this.authHttp.get<Package[]>(this.baseUrl + "/package/active");
  }

  //getcustomer packages
  getCustomerPackages(
    params
  ): Observable<PackageCustomer[]> {
    return this.authHttp.get<any>(
      this.baseUrl + "/package/customer",
      { params: toHttpParams(params) }
    ).pipe(map(result=>result.data));
  }

  addCustomerPackage(data): Observable<PackageCustomer> {
    return this.authHttp.post<PackageCustomer>(
      this.baseUrl + "/package/customer",
      data
    );
  }

  removeCustomerPackage(id): Observable<PackageCustomer> {
    return this.authHttp.delete<any>(this.baseUrl + "/package/customer/" + id);
  }

  updateCustomerPackage(packageId, data) {
    return this.authHttp.put<any>(
      this.baseUrl + "/package/customer/" + packageId,
      data
    );
  }
}
