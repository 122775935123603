import {Resource} from './Resource';
import {Course} from './Course';
import {Serializable} from './Serializable';

export class CourseResourceRequirement implements Serializable<CourseResourceRequirement> {
  id?: number | string;
  resource: Resource;
  course: Course;

  constructor() {
    this.resource = new Resource();
    this.course = new Course();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.resource = new Resource().deserialize(input.resource);
    this.course = new Course().deserialize(input.course);
    return this;
  }
}
