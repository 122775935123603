import {Company} from './Company';
import {Course} from './Course';
import {DiscountType} from './DiscountCode';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';

export class Offer implements Serializable<Offer> {
  id: string | number;
  description: string;
  company: Company;
  triggerCourses: Course[];
  affectedCourses: Course[];
  discountType: DiscountType;
  amount: string;
  startDate: Date;
  endDate: Date | null;


  constructor() {
    this.id = UUID();

    this.company = new Company();
    this.triggerCourses = [];
    this.affectedCourses = [];
    this.discountType = DiscountType.FIXED_AMOUNT;
    this.amount = '0.0';
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.description = input.description;
    this.company = new Company().deserialize(input.company);

    if (input.triggerCourses) {
      this.triggerCourses = input.triggerCourses.map(item => {
        const triggerCourse = new Course().deserialize(item);
        return triggerCourse;
      });
    }

    if (input.affectedCourses) {
      this.affectedCourses = input.affectedCourses.map(item => {
        const affectedCourse = new Course().deserialize(item);
        return affectedCourse;
      });
    }

    this.discountType = input.discountType;
    this.amount = input.amount;
    this.startDate = input.startDate;
    this.endDate = input.endDate;

    return this;
  }
}
