import {Company} from './Company';
import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';


export class CancellationPolicy implements Serializable<CancellationPolicy> {
  id: string | number;
  title: string;
  content: string;
  company: Company;

  constructor() {
    this.id = UUID();
    this.company = new Company();
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.title = input.title;
    this.content = input.content;
    this.company = new Company().deserialize(input.company);
    return this;
  }
}
