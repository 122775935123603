import {Component, OnInit} from '@angular/core';
import {BaThemeSpinner} from '../../baThemeSpinner.service';

@Component({
  selector: 'app-loader',
  template: `
    <div class="preloader" *ngIf="baThemeSpinner.isShown()">
      <div></div>
    </div>
  `,
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public baThemeSpinner: BaThemeSpinner;

  constructor(private _baThemeSpinner: BaThemeSpinner) {
    this.baThemeSpinner = _baThemeSpinner;
  }

  ngOnInit() {
  }

}
