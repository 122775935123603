import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { forkJoin, Observable, of, Subscription } from "rxjs";

import { ApiService } from "../../../_services/api.service";
import { Contact } from "../../../../models/Contact";
import { CourseRunBooking } from "../../../../models/CourseRunBooking";
import { compareFunc } from "../../../utils/Utils";
import {
  catchError,
  first,
  map,
  startWith,
  switchMap,
  take,
  tap,
  takeUntil,
} from "rxjs/operators";
import * as moment from "moment";
import { ParticipantStatusModalComponent } from "../../courseCalendar/participant-status-modal/participant-status-modal.component";
import { BookingParticipant } from "../../../../models/BookingParticipant";
import { BsModalService } from "ngx-bootstrap";
import { Membership } from "../../../../models/Membership";
import "moment-timezone/index";
import { ToastrService } from "ngx-toastr";
import { GiftVoucher } from "../../../../models/GiftVoucher";
import {
  FormControl,
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { SalesCategory } from "../../../../models/SalesCategory";
import { SystemCompanyService } from "../../../_services/system-company.service";
import { PackageCourse } from "models/PackageCourse";
import { PackageCustomer } from "models/PackageCustomer";
import { Package } from "models/Package";
import { OrderStatus } from "models/OrderStatus";

@Component({
  selector: "app-customer-edit",
  templateUrl: "./customer-edit.component.html",
  styleUrls: ["./customer-edit.component.scss"],
})
export class CustomerEditComponent implements OnInit, OnDestroy {
  compareFunc = compareFunc;
  salesCategorySelector = new FormControl();
  @ViewChild("contactForm", { static: false }) contactForm;
  isRequesting = false;
  @Input() isClient: boolean = false;
  @Input() isModal: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() buttonValue: string = "Save Changes";
  @Output("changesSaved") changesSaved = new EventEmitter<Contact>();

  memberships$: Observable<Membership[]>;
  subscriptions: Subscription[] = [];
  uploadOptions = {
    type: "uploadAll",
    url: "https://api.lagoon.co.uk/uploadProfilePicture.php",
    method: "POST",
  };
  countries$: Observable<any>;
  fileError: string;
  courseRunBookings$= [];
  courseRunBookingsFiltered=[];
  salesCategories$: Observable<SalesCategory[]>;
  giftVouchers$: Observable<GiftVoucher[]>;
  loadingBookings: boolean;
  customerPackages: PackageCustomer[];
  packages: Package[];
  @ViewChild("addPackageModal", { static: true }) addPackageModal;
  packageData: FormGroup;
  modalRef: any;
  constructor(
    private apiSvc: ApiService,
    private toastySvc: ToastrService,
    private modalSvc: BsModalService,
    private companySvc: SystemCompanyService,
    private _fb: FormBuilder
  ) {
    this.packageData = this._fb.group({
      packageId: [null, Validators.required],
      qty: [1, Validators.required],
      totalTokens: [null , Validators.required],
    });
  }

  private _contact: Contact;

  get contact(): Contact {
    return this._contact;
  }

  @Input()
  set contact(contact) {
    if (contact) {
      this._contact = contact;
      if (contact.id) {
        
        this.subscriptions.push( 
           this.apiSvc.getCustomerBookings(contact.id)
           //.pipe(
          //   map(bookings=>{
          //     bookings.map(b=>{
          //       b.startDate=moment.utc( b.startDate).format("YYYY-MM-DDTHH:mm+00");
          //       b.endDate=moment( b.endDate).format("YYYY-MM-DDTHH:mm");
          //       return b;
          //     });
          //     return bookings;
          //   })
          // )
          .subscribe(bookings=>{
            console.log(bookings);            
          
          this.courseRunBookings$=bookings;
          this.courseRunBookingsFiltered=bookings;
        }));
        this.salesCategorySelector.valueChanges.pipe(
          tap((salesCategoryId) => {
            if(salesCategoryId!="null")      
            {              
            this.courseRunBookingsFiltered=this.courseRunBookings$.filter(b=>b.salesCategoryId==salesCategoryId)
            }
            else 
            this.courseRunBookingsFiltered=[...this.courseRunBookings$];
          })
        ).subscribe(r=>console.log(r))
        
        // else
        // this.courseRunBookings$ = this.salesCategorySelector.valueChanges.pipe(
        //   tap(() => {
        //     this.loadingBookings = true;
        //   }),
        //   startWith(<string>null),
        //   switchMap((salesCategoryId) => {
        //     //debugger;
        //     const params = {
        //       "no-page": true,
        //       jsonQuery: {
        //         "customer.id": this.contact.id,
        //       },
        //       order: { id: "desc" },
        //     };

        //     const clientIsParticipantParams = {
        //       "no-page": true,
        //       jsonQuery: {
        //         "participants.contact.id": this.contact.id,
        //         "customer.id": { "!$eq": this.contact.id },
        //       },
        //     };
        //     if (salesCategoryId && salesCategoryId != "null") {
        //       params.jsonQuery[
        //         "courseRun.course.salesCategory.id"
        //       ] = salesCategoryId;
        //       clientIsParticipantParams.jsonQuery[
        //         "courseRun.course.salesCategory.id"
        //       ] = salesCategoryId;
        //     }

        //     return forkJoin([
        //       this.apiSvc.getBookings(params).pipe(
        //         map((collection) => collection.data),
        //         map((courseRunBookings) =>
        //           courseRunBookings.map((courseRunBooking) => {
        //             const test = new CourseRunBookingWithType();
        //             const customerParticipant = courseRunBooking.participants.find(
        //               (x) => x.contact.id == this.contact.id
        //             );
        //             if (customerParticipant) {
        //               Object.assign(test, courseRunBooking, {
        //                 customerParticipant: customerParticipant,
        //               });
        //             }
        //             return Object.assign(test, courseRunBooking, {
        //               type: "Customer",
        //             });
        //           })
        //         )
        //       ),
        //       this.apiSvc.getBookings(clientIsParticipantParams).pipe(
        //         map((collection) => collection.data),
        //         map((courseRunBookings) =>
        //           courseRunBookings.map((courseRunBooking) => {
        //             const test = new CourseRunBookingWithType();
        //             const customerParticipant = courseRunBooking.participants.find(
        //               (x) => x.contact.id == this.contact.id
        //             );
        //             if (customerParticipant) {
        //               Object.assign(test, courseRunBooking, {
        //                 customerParticipant: customerParticipant,
        //               });
        //             }

        //             return Object.assign(test, courseRunBooking, {
        //               type: "Participant",
        //             });
        //           })
        //         )
        //       ),
        //     ]);
        //   }),
        //   map(([bookingsOwned, bookingsAsParticipant]) => {
        //     return [...bookingsOwned, ...bookingsAsParticipant];
        //   }),
        //   switchMap((courseRunBookings) => {
        //     if (!courseRunBookings.length) {
        //       return of([]);
        //     }
        //     return forkJoin(
        //       courseRunBookings.map((courseRunBooking) => {
        //         return this.apiSvc.getOrder(courseRunBooking.order.id).pipe(
        //           map((order) => {
        //             courseRunBooking.order = order;
        //             return courseRunBooking;
        //           })
        //         );
        //       })
        //     );
        //   }),
        //   tap(() => {
        //     setTimeout(() => {
        //       this.loadingBookings = false;
        //     }, 100);
        //   })
        // );

        this.memberships$ = this.apiSvc
          .getMemberships({
            jsonQuery: { "customer.id": contact.id },
            "no-page": true,
            order: { id: "desc" },
          })
          .pipe(map((x) => x.data));

        const boughtGiftVouchersParams = {
          "no-page": true,
          jsonQuery: {
            "requestedBy.id": this.contact.id,
            "company.id": this.contact.company.id,
          },
          order: { id: "d" },
        };

        const assignedGiftVouchersParams = {
          "no-page": true,
          jsonQuery: {
            "assignedTo.id": this.contact.id,
            "company.id": this.contact.company.id,
          },
        };

        this.giftVouchers$ = forkJoin(
          this.apiSvc.getGiftVouchers(boughtGiftVouchersParams),
          this.apiSvc.getGiftVouchers(assignedGiftVouchersParams)
        ).pipe(
          map(([boughtGiftVouchers, assignedGiftVouchers]) => {
            return [...boughtGiftVouchers.data, ...assignedGiftVouchers.data];
          })
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  test() {
    console.log(this.contact);
  }

  ngOnInit() {
    this.countries$ = this.apiSvc.getCountries();
    this.salesCategories$ = this.companySvc.getCompany().pipe(
      switchMap((company) => {
        return this.apiSvc.getSalesCategories({
          "no-page": true,
          jsonQuery: { "company.id": company.id },
        });
      }),
      map((collection) => collection.data)
    );
    this.getPackages();
  }

  onSubmit() {
    this.changesSaved.next(this.contactForm.value);
  }

  pdfUploaded($event: any) {
    console.log($event);
    console.log($event.file.response);
    if ($event.type === "done") {
      if ($event.file.response.error) {
        this.fileError = $event.file.response.error;
        return;
      }
      this.contact.profilePicture = $event.file.response.path;
    }
  }

  resendConfirmationEmail(courseRunBooking: CourseRunBooking) {
    this.apiSvc
      .resendConfirmationEmail(courseRunBooking)
      .pipe(first())
      .subscribe(() => {
        this.toastySvc.success("Sent");
      });
  }

  matchDates(startDate, endDate) {
    return (
      moment(startDate).format("YYYY-MM-DD") ===
      moment(endDate).format("YYYY-MM-DD")
    );
  }

  timeZone(date, format, tz) {
    if(date!=null)
    {
      return moment.utc(date).tz(tz).format(format);
    }
    else return '';
  }

  changeParticipantStatus($event, booking) {
    const bookingParticipant=new BookingParticipant();
    bookingParticipant.notes=booking.customerNotes;
    bookingParticipant.status=booking.customerStatus;
    bookingParticipant.id=booking.bookingParticipantId;
    const modal = this.modalSvc.show(ParticipantStatusModalComponent);
    const component = <ParticipantStatusModalComponent>modal.content;
    component.participant = bookingParticipant;

    this.modalSvc.onHidden
      .pipe(
        take(1),
        switchMap((dismissReason) => {
          if (dismissReason === ParticipantStatusModalComponent.ACCEPT_REASON) {
            bookingParticipant.status = $event;
            bookingParticipant.notes = component.notes;
            return this.apiSvc.updateBookingParticipant(bookingParticipant).pipe(
              map((success) => {
                this.toastySvc.success("Updated status");
                return true;
              }),
              catchError((error) => {
                this.toastySvc.error("Failed to update status");
                return of(false);
              })
            );
          }
          return of(false);
        })
      )
      .subscribe((success) => {
        if (!success) {
          const currentStatus = bookingParticipant.status;
          booking.status = null;
          setTimeout(() => {
            booking.status = currentStatus;
          });
        }
      });
  }

  resendVoucher(id: any) {
    this.apiSvc.resendGiftVoucher(id).subscribe(
      (success) => {
        this.toastySvc.success("Sent!");
      },
      (error) => {
        let errorMessage = "Failed to send gift voucher: Server Error";

        if (error.error && error.error.description) {
          errorMessage = `${errorMessage}, ${error.error.description}`;
        }
        this.toastySvc.error(errorMessage);
      }
    );
  }

  addPackage() {
    const data = this.packageData.value;
    data.customerId = this._contact.id;
    data.remainTokens = data.totalTokens;
    console.log("data", data);
    this.apiSvc.addCustomerPackage(data).subscribe((response) => {
      console.log(response);
      this.modalSvc.hide(1);
      this.getPackages();
      this.toastySvc.success("Package added successfully");
    });
  }

  openAddPackage() {
    this.modalRef=this.modalSvc.show(this.addPackageModal);    
  }

  getPackages() {
    this.apiSvc.getPackages().subscribe((packages) => {
      this.packages = packages;
     
    });
        // $or:[          
        //   {'bookingOrder.status':OrderStatus.CONFIMED},          
        //   {'bookingOrder':{$isNull:true}},
        // ],
    const params =
    {
      jsonQuery: {
    
        'customer.id': this._contact.id
       },
      'no-page': true, 'groupName': 'staffTitleOnly'
    };
    this.apiSvc
      .getCustomerPackages(params)
      .subscribe((data) => (this.customerPackages = data));
    this.packageData.controls.packageId.valueChanges.subscribe((val) => {
      const selectedPackage = this.packages.find((p) => p.id == val);
      const totalTokens =
        this.packageData.value.qty * selectedPackage.tokensNumber;
      this.packageData.patchValue({ totalTokens: totalTokens });
    });
    this.packageData.controls.qty.valueChanges.subscribe((val) => {
      if (this.packageData.value.packageId != null) {
        const selectedPackage = this.packages.find(
          (p) => p.id == this.packageData.value.packageId
        );
        const totalTokens =
          this.packageData.value.qty * selectedPackage.tokensNumber;
        this.packageData.patchValue({ totalTokens: totalTokens });
      }
    });
  }

  removePackage(id) {
    if (confirm("Are you sure you want to delete this?")) {
      console.log(this.customerPackages, id);
      this.apiSvc.removeCustomerPackage(id).subscribe(
        (result) => {
          this.toastySvc.success("Package removed successfully");
          this.customerPackages = this.customerPackages.filter(
            (p) => p.id != id
          );
          console.log(this.customerPackages, id);
        },
        (error) => {
          if (error.status === 406)
            this.toastySvc.error(
              "Package can't be deleted because of bookings founded"
            );
        }
      );
    }
  }
}

export class CourseRunBookingWithType extends CourseRunBooking {
  type: "Customer" | "Participant";
  customerParticipant?: BookingParticipant;
}
