import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgForm} from '@angular/forms';
import {compareFunc} from '../../../utils/Utils';
import {Subject} from 'rxjs';
import {GiftVoucher} from '../../../../models/GiftVoucher';
import {ActivatedRoute} from '@angular/router';
import {ApiService} from '../../../_services/api.service';
import {ContactWithRelationType} from '../../../../models/ContactWithRelationType';
import {map, takeUntil} from 'rxjs/operators';
import * as moment from 'moment';
import {Moment} from 'moment';

@Component({
  selector: 'pavweb-voucher',
  templateUrl: './gift-voucher.component.html',
  styleUrls: ['./gift-voucher.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GiftVoucherComponent),
      multi: true
    }
  ]
})
export class GiftVoucherComponent implements OnInit, OnDestroy {

  @ViewChild('form', {static: true}) form: NgForm;
  @ViewChild('focusMe', {static: true}) focusInput: HTMLElement;

  compareContact = compareFunc;
  private _destroySubject: Subject<null> = new Subject<null>();
  _giftVoucher: GiftVoucher = new GiftVoucher();

  value;

  today = new Date();

  voucherImages;

  constructor(private route: ActivatedRoute, private apiSvc: ApiService) {
  }


  @Input()
  get giftVoucher(): GiftVoucher {
    return this._giftVoucher;
  }

  set giftVoucher(giftVoucher: GiftVoucher) {
    if (giftVoucher) {
      this._giftVoucher = giftVoucher;
    }
  }

  private _contacts: ContactWithRelationType[] = [];

  @Input()
  set contacts(contacts) {
    this._contacts = contacts;
  }

  @Output() isValidated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  timeToSendOptions: any = [];

  get contacts(): ContactWithRelationType[] {
    return this._contacts;
  }

  ngOnInit() {

    this.voucherImages = this.apiSvc.getGiftVoucherImages().pipe(
      map(x => x.data));

    const start = moment().startOf('day');
    const end = moment().endOf('day');
    while (start.isBefore(end)) {
      this.timeToSendOptions.push(
        {
          realTime: start.format('HH:mm'),
          stupidTime: start.format('hh:mm A')
        }
      );
      start.add(1, 'hour');
    }
    this.form.valueChanges.pipe(
      takeUntil(this._destroySubject)
    ).subscribe((value) => {
      this.value = value;
      this.isValidated.next(this.form.valid);
    });
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
  }

  changeAmount(amount) {
    if (amount >= 25) {
      this._giftVoucher.amount = amount;
    }
  }

  futureDates($event: Moment) {
    return $event.isSameOrAfter(moment(), 'day');
  }

  submit() {
    // const formValue = this.form.value;
    // formValue.amount = this._giftVoucher.amount;
    this.onSubmit.next(this.form.value);
  }

  changeAssignedTo() {
    if (this.giftVoucher.assignedTo) {
      this.form.controls.firstName.setValue(this.giftVoucher.assignedTo.firstName);
      this.form.controls.lastName.setValue(this.giftVoucher.assignedTo.lastName);
      this.form.controls.emailTo.setValue(this.giftVoucher.assignedTo.email);
    } else {

      this.form.controls.firstName.setValue('');
      this.form.controls.lastName.setValue('');
      this.form.controls.emailTo.setValue('');
    }
  }
}
