<div class="modal-header">
  <h4 class="modal-title pull-left">Add Client </h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <label *ngIf="searchStep">
    Contact Type:
    <select [(ngModel)]="selectedContactType">
      <option value="Partner">Partner</option>
      <option value="Child">Child</option>
      <option value="Friend">Friend</option>
    </select>
  </label>
  <form #contactForm="ngForm"
        *ngIf="(selectedContactType !== 'Friend') || (selectedContactType == 'Friend' && !searchStep)"
        (ngSubmit)="submitForm(contactForm)">
    <div *ngIf="selectedContactType == 'Friend' && !searchStep">
      Couldn't find Friend please add your Friend or retry search
    </div>
    <div class="form-group">
      <label for="firstName">First Name</label>
      <input type="text" id="firstName" name="firstName" class="form-control" ngModel required>
    </div>
    <div class="form-group">
      <label for="lastName">Last Name</label>
      <input type="text" id="lastName" name="lastName" class="form-control" ngModel required>
    </div>
    <div class="form-group" *ngIf="selectedContactType === 'Child'">
      <label for="dateOfBirth">
        Date of Birth:
      </label>
      <input type="text" id="dateOfBirth" bsDatepicker #dp="bsDatepicker" class="form-control"
             placeholder="Date of Birth" name="dob" ngModel required>
    </div>

    <div class="form-group">
      <label for="phone">Phone: (optional)</label>
      <input type="text" id="phone" name="phone" class="form-control" ngModel>
    </div>
    <div class="form-group">
      <label for="email">Email: (optional)</label>
      <input type="text" id="email" name="email" class="form-control" ngModel>
    </div>
    <div class="form-group">
      <button class="btn btn-primary" type="submit">Add {{selectedContactType}}</button>
      <button *ngIf="selectedContactType == 'Friend' && !searchStep" class="btn btn-auth"
              (click)="searchStep = true" type="button">
        Retry Search
      </button>
    </div>
  </form>
  <form #searchForm="ngForm" *ngIf="selectedContactType === 'Friend' && searchStep">
    <div class="form-group">
      Search Friend by Username or Email
      <div class="radio">
        <label>
          <input type="radio" name="searchType" required [(ngModel)]="searchData.type" value="email" email>
          Email
        </label>
      </div>
      <div class="radio">
        <label>
          <input type="radio" name="searchType" required [(ngModel)]="searchData.type" value="username">
          Username
        </label>
      </div>
      <input type="text" [placeholder]="searchData.type == 'email' ? 'Email Address' : 'Username' " class="form-control"
             [(ngModel)]="searchData.value"
             name="searchValue">
    </div>
    <div class="form-group">
      <button class="btn btn-success" type="button" (click)="searchFriend()">Search Friend</button>
    </div>
  </form>
</div>
<div class="modal-footer">
  <span *ngIf="error" class="error-block">{{error}}</span>
  <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Close</button>
</div>
