import {CourseRunBooking} from './CourseRunBooking';
import {Contact} from './Contact';
import {Invoice} from './Invoice';
import {Serializable} from './Serializable';

export class Payment implements Serializable<Payment> {
  id: number;
  courseRunBooking: CourseRunBooking;
  paymentMethod: string;
  amount: string;
  createdAt: string;
  paidAt: string;
  createdBy: Contact;
  status: string = 'paid';
  paymentRef?: string;
  extraData?: string;
  invoice: Invoice;
  dueDate?: string;
  subPaymentType: string;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.courseRunBooking = new CourseRunBooking().deserialize(input.courseRunBooking);
    this.paymentMethod = input.paymentMethod;
    this.amount = input.amount;
    this.createdAt = input.createdAt;
    this.paidAt = input.paidAt;
    this.createdBy = new Contact().deserialize(input.createdBy);
    this.status = input.status;
    this.paymentRef = input.paymentRef;
    this.extraData = input.extraData;
    this.invoice = new Invoice().deserialize(input.invoice);
    this.dueDate = input.dueDate;

    return this;
  }
}
