import {Injectable} from '@angular/core';

@Injectable()
export class BaThemeSpinner {

  private _isShown = true;

  constructor() {

  }

  public isShown(): boolean {
    return this._isShown;
  }

  public show(): void {
    this._isShown = true;
  }

  public  hide(delay: number = 0): void {
    setTimeout(() => {
        this._isShown = false;
      },
      delay
    );
  }
}
