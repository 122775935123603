import {UUID} from '../app/utils/Utils';
import {Serializable} from './Serializable';


export class PhysicalLocation implements Serializable<PhysicalLocation> {
  id: number | string;
  name: string;
  enabled = true;

  constructor() {
    this.id = UUID();
    this.name = '';
  }

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.name = input.name;
    this.enabled = input.enabled;
    return this;
  }
}


