import {Directive, ElementRef, forwardRef, HostListener, Input} from '@angular/core';
import {AbstractControl, NG_ASYNC_VALIDATORS, Validator} from '@angular/forms';
import {ApiService} from '../_services/api.service';
import {map} from 'rxjs/operators';

@Directive({
  selector: '[discountCode][formControlName], [discountCode][formControl], [discountCode][ngModel]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => DiscountCodeDirective), multi: true
    }
  ]
})
export class DiscountCodeDirective implements Validator {
  @Input('discountCode') codeId: number | string;

  constructor(private elementRef: ElementRef, private apiSvc: ApiService) {

  }

  @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if (e.which === 32) {
      return false;
    }
    return e;
  }

  @HostListener('change') onChange(e) {
    this.elementRef.nativeElement.value = this.elementRef.nativeElement.value.replace(/\s/g, '');
  }

  validate(c: AbstractControl): { [key: string]: any; } {

    const v = c.value;

    let params: any = {
      jsonQuery: {code: v}
    };

    if (this.codeId > 0) {
      params.jsonQuery = {
        ...params.jsonQuery,
        id: {'!$eq': this.codeId}
      };
    }

    return this.apiSvc.getDiscountCodes(params).pipe(
      map((discountCodeCollection) => {
        if (discountCodeCollection.data.length > 0) {
          return {nonUniqueDiscountCode: true}
        }
        return null;
      })
    );
  }


}
