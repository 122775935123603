import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator} from '@angular/forms';
import {ColorPickerConfiguration} from './models';
import {IColorPickerConfiguration} from './interfaces';

@Component({
  selector: 'nga-color-picker',
  template: `
    <div class="color-picker-container">
      <ul>
        <li class="color-picker-color" [class.selectedColor]="currentColor == null"
            [style.width]="config.width + 'px'"
            [style.height]="config.height + 'px'"
            [style.border-radius]="config.borderRadius + 'px'"
            [style.border]="'1px solid #FFF'"
            [style.line-height]="'20px'"
            (click)="setColor(null)">?
        </li>
        <li class="color-picker-color" [class.selectedColor]="currentColor == color"
            *ngFor="let color of availableColors"
            [style.width]="config.width + 'px'"
            [style.height]="config.height + 'px'"
            [style.border-radius]="config.borderRadius + 'px'"
            [style.background-color]="color"
            (click)="setColor(color)"
            [title]="color">
        </li>
      </ul>
    </div>
  `,
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true,
    }
  ]
})
export class ColorPickerComponent implements ControlValueAccessor, OnInit, Validator {
  validate(c: AbstractControl): { [key: string]: any; } {
    return null;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public disabled = false;

  public onValidatorChange: Function;

  public currentColor: string = null;

  public onChange: any = Function.prototype;

  public onTouched: any = Function.prototype;

  public config: ColorPickerConfiguration;

  @Input() pickerConfig: IColorPickerConfiguration;

  @Input() availableColors: string[];

  @Input() name: string;

  constructor() {
    this.config = new ColorPickerConfiguration();
  }

  /** OnInit implementation. */
  ngOnInit() {
    this._processOptions(this.pickerConfig);
  }

  /** ControlValueAccessor implementation. */
  writeValue(value: any): void {
    if (value) {
      this.currentColor = value;
    }
  }

  /** ControlValueAccessor implementation. */
  registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  /** ControlValueAccessor implementation. */
  registerOnTouched(fn: (_: any) => {}): void {
    this.onTouched = fn;
  }

  /**
   * Update the current color based on selection.
   *
   * @param {string} color
   *
   * @memberOf ColorPickerComponent
   */
  public setColor(color: string) {
    this.onChange(color);
    this.currentColor = color;
    this.onTouched();
  }

  /**
   * Wire up configuration.
   *
   * @private
   * @param {IColorPickerConfiguration} opts
   *
   * @memberOf ColorPickerComponent
   */
  private _processOptions(opts: IColorPickerConfiguration) {
    if (opts != null) {

      const IsNumber = (val: any) => typeof(val) === 'number';
      const IsArray = (val: any) => Array.isArray(val);

      // width
      if (IsNumber(opts.width)) {
        this.config.width = opts.width;
      }

      // height
      if (IsNumber(opts.height)) {
        this.config.height = opts.height;
      }

      // borderRadius
      if (IsNumber(opts.borderRadius)) {
        this.config.borderRadius = opts.borderRadius;
      }
    }

    if (this.availableColors == null) {
      console.warn('[ng2-color-picker] No available colors provided.');
    }
  }
}
