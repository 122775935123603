import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import * as jQuery from 'jquery';
import {BaMenuService} from '../../services/baMenu';
import {GlobalState} from '../../../global.state';
import {combineLatest, Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';

@Component({
  selector: 'ba-menu',
  templateUrl: './baMenu.html',
  styleUrls: ['./baMenu.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaMenuComponent {

  @Input() sidebarCollapsed = false;
  @Input() menuHeight: number;

  @Output() expandMenu = new EventEmitter<any>();

  public menuItems$: Observable<any[]>;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  public outOfArea: number = -200;

  constructor(private _router: Router, private _service: BaMenuService, private _state: GlobalState) {
  }


  public ngOnInit(): void {


    this._service.menuItems.subscribe((test) => {
      //console.log(test);
    })

    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      startWith(null)
    ).subscribe(event => {
      //  console.log(event);
    })


    this.menuItems$ =
      combineLatest(
        this._service.menuItems,
        this._router.events.pipe(
          filter(event => event instanceof NavigationEnd),
          startWith(null)
        )
      ).pipe(
        map(([menuItems]) => {
          return menuItems;
        }),
        map(items => {
          if (items) {
            const modifiedItems = this._service.selectMenuItem(items);
            this._state.notifyDataChanged('menu.activeLink', this._service.getCurrentItem());
            return modifiedItems;
          }
        })
      )
  }

  public hoverItem($event): void {
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    // TODO: get rid of magic 66 constant
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 66;
  }

  public toggleSubMenu($event): boolean {
    const submenu = jQuery($event.currentTarget).next();

    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
      if (!$event.item.expanded) {
        $event.item.expanded = true;
      }
    } else {
      $event.item.expanded = !$event.item.expanded;
      submenu.slideToggle();
    }

    return false;
  }
}
