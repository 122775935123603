<div *ngIf="priceElement">
  <div class="box">
    Description: {{priceElement.description}}
  </div>
  <div class="box">
    Amount: {{priceElement.amount | currency:billingCompany.currency.code:'symbol'}}
  </div>
  <div class="box">
    Tax Percentage: {{(priceElement.applyTax ? billingCompany.taxRate/100 : 0) | percent:'0.2-2'}}
  </div>
</div>
