import {CourseSession} from './CourseSession';
import {Course} from './Course';
import {Company} from './Company';
import {Serializable} from './Serializable';
import {PriceElement} from './PriceElement';


export class CourseRun implements Serializable<CourseRun> {
  id: number | string;
  course: Course;
  valid?: boolean;
  maxNumbers?: number;
  participantsCount?: number;
  startDate?: string;
  endDate?: string;
  billingCompany?: Company;
  courseSessions?: CourseSession[] = [];
  priceElements: PriceElement[] = [];
  price: number;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.course = new Course().deserialize(input.course);
    this.valid = input.valid;
    this.maxNumbers = input.maxNumbers;
    this.participantsCount = input.participantsCount;
    this.startDate = input.startDate;
    this.endDate = input.endDate;
    this.billingCompany = new Company().deserialize(input.billingCompany);
    this.courseSessions = [];
    this.priceElements = [];
    this.price = input.price;

    if (input.courseSessions) {
      this.courseSessions = input.courseSessions.map(item => {
        const courseSession = new CourseSession().deserialize(item);
        courseSession.courseRun = this;
      });
    }
    if (input.priceElements) {
      this.priceElements = input.priceElements.map(item => {
        return new PriceElement().deserialize(item);
      })
    }

    return this;
  }
}



