import {Course} from './Course';
import {Serializable} from './Serializable';

export class SubCourse implements Serializable<SubCourse> {
  id?: number;
  title: string;
  description: string;
  course: Course;

  deserialize(input) {
    if (!input) {
      return null;
    }
    this.id = input.id;
    this.title = input.title;
    this.description = input.description;
    this.course = new Course().deserialize(input.course);
    return this;
  }
}
