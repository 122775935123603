import {GiftVoucher} from './GiftVoucher';
import {Invoice} from './Invoice';
import {Serializable} from './Serializable';

export class GiftVoucherTransaction implements Serializable<GiftVoucherTransaction> {
  id: number | string;
  giftVoucher: GiftVoucher;
  invoice: Invoice;
  amount: string;

  deserialize(input) {
    if (!input) {
      return null;
    }

    this.id = input.id;
    this.giftVoucher = new GiftVoucher().deserialize(input.giftVoucher);
    this.invoice = new Invoice().deserialize(input.invoice);
    this.amount = input.amount;

    return this;
  }
}
