<form #form="ngForm">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Invoice Item</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" *ngIf="item && company">

    <div class="form-group">
      <label for="description">Description: </label>
      <input id="description" class="form-control" [(ngModel)]="item.description" name="description" required>
    </div>

    <div class="form-group">
      <table>
        <tr>
          <td width="80">
            <label for="quantity">Qty</label>
            <div class="input-group" style="width: 60px;">
              <input style=" border-radius: 0; margin-right: 6px; float: left" type="number" step="1" id="quantity"
                     class="form-control" required [(ngModel)]="item.quantity"
                     name="quantity" value="1" min="1">
            </div>
          </td>
          <td width="170">
            <label for="taxSelector">Mode</label>
            <div class="input-group" style="width: 150px">
              <select style="border-radius: 0" class="form-control" [(ngModel)]="taxSelector"
                      name="taxSelector" id="taxSelector"
                      (ngModelChange)="changedSelector($event)" required="required">
                <option [ngValue]="'no-tax'">No Tax</option>
                <option [ngValue]="'plus-tax'">Plus Tax</option>
                <option [ngValue]="'inc-tax'">Including Tax</option>
              </select>
              <input type="hidden" name="taxPercentage" [ngModel]="item.taxPercentage ">
            </div>
          </td>
          <td width="150">
            <div *ngIf="form.value.taxSelector !== 'inc-tax'">
              <label for="amount">Amount</label>
              <div class="input-group">
                <span class="input-group-addon">{{company.currency.symbol}}</span>
                <input type="number" step="0.01" id="amount" class="form-control" required [(ngModel)]="item.amount"
                       name="amount" value="0" style="width: 100px">
              </div>
            </div>

            <div *ngIf="form.value.taxSelector == 'inc-tax'">
              <label for="incTaxAmount">Amount Inc. Tax</label>
              <div class="input-group">
                <span class="input-group-addon">{{company.currency.symbol}}</span>
                <input type="number" step="0.01" id="incTaxAmount" class="form-control" required
                       name="amount" value="0" style="width: 100px" [(ngModel)]="providedTotal">
              </div>
            </div>

          </td>
          <td width="200">
            <label for="gross">Total</label>
            <div class="input-group">
              <span class="input-group-addon">{{company.currency.symbol}}</span>
              <input type="number" step="0.01" id="gross" class="form-control" required
                     [ngModel]="getTotal().toNumber()"
                     name="gross" value="0" disabled>
            </div>
          </td>
        </tr>
      </table>

    </div>


    <div class="form-group">
      <b>Mode</b><br/>

      <div style="font-size: 12px; color: #666">
        No Tax = Tax is NOT applicable to this Item<br/>
        Plus Tax = Enter the price into Amount BEFORE Tax is added<br/>
        Including Tax = Enter the price into Amount which INCLUDES Tax
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveInvoiceItem(form)">Save Invoice Item</button>
    <button type="button" class="btn btn-success" (click)="bsModalRef.hide()">Close</button>
  </div>
</form>
