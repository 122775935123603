import {Country} from './Country';
import {Serializable} from './Serializable';

export class BillingAddress implements Serializable<BillingAddress> {
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  vatNumber: string;
  postCode: string;
  country: Country = null;

  constructor() {
    this.country=new Country();
  }

  deserialize(input) {

    if (!input) {
      return null;
    }
    this.addressLine1 = input.addressLine1;
    this.addressLine2 = input.addressLine2;
    this.city = input.city;
    this.county = input.county;
    this.vatNumber = input.vatNumber;
    this.postCode = input.postCode;
    this.country = new Country().deserialize(input.country);

    return this;
  }
}
